import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import images from '../../assets/varibale-list/images'
import PageData from '../../MetaData/PageContentData'
import BranTypeData from '../../MetaData/BrandIdentityData'
import './brand-identity-type.css'
import ContinuousSlider from '../../components/Controllers/Slider/Slider'
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingTop:"20px"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const CardFontControllerGenrator= ({brandIdentityType,setBrandIdentityType}) =>{
    if (BranTypeData.length > 0) {
        return        BranTypeData.map(function (each) {
            return (
                <Grid item md={12} sm={12} xs={12}>
                   <ContinuousSlider
                   sliderId={each.Id} 
                   leftString={each.LeftString} 
                    rightString={each.RightString}
                    brandIdentityType={brandIdentityType}
                    setBrandIdentityType={setBrandIdentityType}
                     />
                </Grid>)
        })
    } else {
        return []
    }
}

const BrandIdentityType = ({PageId,PageNumber, brandIdentityType,setBrandIdentityType}) => {

    const classes = useStyles();

    const _PageId = PageId;
    const _PageNumber=PageNumber;
    const imagelist = images;
    const numbertitle = PageData[_PageId - 1].PageNumberTittle
    const pageTitle = PageData[_PageId - 1].PageTittle
    const subtitle = PageData[_PageId - 1].Subtitle
    const nextStep= PageData[_PageId ].href


    return (
       
     
                
                            <div >
                                <div className={classes.root}>
                                    <Grid container spacing={3}>
                                                                                <Grid item md={2} xs={0}></Grid>
                                        <Grid item md={8} xs={12} style={{textAlign:'center'}}>

                                        < CardFontControllerGenrator
                                        
                                        brandIdentityType={brandIdentityType}
                                        setBrandIdentityType={setBrandIdentityType}
                                        
                                        
                                        />

                                        </Grid>
                                        <Grid item md={2} xs={0}></Grid>
                           
                                       
                                       
                                    </Grid>

                                </div>
                            </div>
                       
              
          
    )
}

export default BrandIdentityType
