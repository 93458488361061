import React from 'react'
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types'
import './color-palette.css'



const NavigatorGen= ({ ColorList }) =>{


  if (ColorList.length > 0) {
      return        ColorList.map(function (each,index) {
          return (
            <Grid item xs={2} className={ `${each+(index+1)} css_colour_inner_palette`} ></Grid>
              
              )

      })
  } else {
      return []
  }



}






function ColourPalette({ColorList}) {
    return (
        <div>
          <div className="css_colour_container">
        
          <Grid container spacing={0}>
                                  <NavigatorGen ColorList={ColorList}></NavigatorGen>
</Grid>



          </div>

        </div>
    )
}


ColourPalette.defaultProps = {
  ColorList: [  'red1','red2','red3','red4','red5','red6']
 
}



ColourPalette.propTypes = {
  ColorList:PropTypes.array
}

export default ColourPalette

