import React from 'react'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CardController from '../../components/Cards/Colour/ColourPaletteMainController';
import images from '../../assets/varibale-list/images'
import PageData from '../../MetaData/PageContentData'
import ColourData from '../../MetaData/ColourData';
import './colourpalette.css'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


const CardColorPaletteControllerGenrator= ({setColorId,selectedId,ColorListSelected,setSelectedColorList}) =>{
    if (ColourData.length > 0) {
        return        ColourData.map(function (colourDetail) {
            return (
                <Grid item md={2}>
                
                    <CardController 
                        ColourData={colourDetail}    
                        colourPaletteOnSelect={setColorId}    
                            IsSelected={selectedId==colourDetail.ColourId}
                          SelectedId={selectedId}
                            ColorListSelected={ColorListSelected}
                            setSelectedColorList={setSelectedColorList}
                    />
                </Grid>)
        })
    } else {
        return []
    }
}


const colourPaletteOnSelect=(colorId)=>
{
}



const ColourPalette = ({PageId,PageNumber,dataSetter,ColorListSelected}) => {
    const _PageId = PageId;
    const _PageNumber = PageNumber;
    const classes = useStyles();
    const imagelist = images;
    const numbertitle = PageData[_PageId - 1].PageNumberTittle
    const pageTitle = PageData[_PageId - 1].PageTittle
    const subtitle = PageData[_PageId - 1].Subtitle
    const nextStep= PageData[_PageId ].href
    const [ColorId, setColorId] = React.useState(0);
   // const [ColorListSelected, setSelectedColorList] = React.useState([]);


    return (
 
            <div className='container'>
                <div className='inGrid'>
                   
                            <div >
                                <div className={classes.root}>
                                    <Grid container spacing={3} id="centerflex">
                                      
                                        <CardColorPaletteControllerGenrator  
                                        colourPaletteOnSelect={colourPaletteOnSelect}
                                        setColorId={setColorId}
                                        selectedId={ ColorId}
                                        ColorListSelected={ColorListSelected}
                                        setSelectedColorList={dataSetter}
                                        />
                                        <Grid item xs={12}></Grid>
                                     
                                    </Grid>

                                </div>
                            </div>
                     
                </div>
              
            </div>
       
    )
}

export default ColourPalette
//     {ColorId}  Selected data{ColorListSelected.toString()}