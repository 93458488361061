import axios from "axios";
import ApiList from "../ApiConfig/ApiList";


export function saveLogoTypeData(currentSaveId, email, data, setNextOnCallback, setError, setLoadStatus) {
  const dataForSent = data.map((k) => {
    return { id: k, state: true }
  })
  var obj = { email: email, data: dataForSent }

  axios.post(ApiList.API_SAVE_LOGO_TYPE,
    JSON.stringify(obj), {
    headers: {
      'Content-Type': 'application/json',
    }
  }
  )
    .then((response) => {

      if (response.data.success == true) {
        setLoadStatus(false)
        setNextOnCallback(currentSaveId + 1);
      } else {
        console.warn(response.data.message);
      }
    }, (error) => {
      console.warn(error);
      setError(true)
    });
}

export function getLogoTypeData(email, callbackevent, setError, paramName) {

  var api_gen = require('../ApiConfig/ApiGen')(paramName, email);
  axios.get(ApiList.API_GET_LOGO_TYPE + `${api_gen}`

  )
    .then((response) => {
      let rtArrayData = [];
      if (response.data.data != null && response.data.data.length > 0) {
        rtArrayData = response.data.data.map((k) => { return eval(k.id) })
      }
      callbackevent(rtArrayData);
      setError(false)
    }, (error) => {
      console.warn(error);
      setError(true)
    });
}


export function getLogoTypeDataNoCallBack(email) {

  let rtArrayData = [];
  axios.get(ApiList.API_GET_LOGO_TYPE + `?email=${email}`

  )
    .then((response) => {
      if (response.data.data != null && response.data.data.length > 0) {
        rtArrayData = response.data.data.map((k) => { return eval(k.id) })
      }

    }, (error) => {
      console.warn(error);

    });

  return rtArrayData;
}