import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import SelectButtonControl from '../../Controllers/SelectButton/SelectButtonControl'
import Grid from '@material-ui/core/Grid';
import './ColourPaletteMainController.css'
import './colour-palette-class.css'
import { makeStyles } from '@material-ui/core/styles';
import ColourPalette from '../../Controllers/ColourPalette/ColourPalette'

//import '../../assets/images/LgoTypes'




const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const CardColorPaletteDescritopm = ({ ColourName, DescriptionLine1, DescriptionLine2 }) => {

    return (
        <div>
            <div className="css_colour_main_text">
                {ColourName}
            </div>
            <div className="css_colour_description">
                <p>{DescriptionLine1}</p>
            </div>
            <div className="css_colour_description">
                <p>{DescriptionLine2}</p>
            </div>
        </div>
    )
}







function ColourPaletteMainController({ ColourData, colourPaletteOnSelect, SelectedId, ColorListSelected, IsSelected, setSelectedColorList }) {
    const [checked, setChecked] = React.useState(IsSelected)
    const classes = useStyles();

    useEffect(() => {
        colourPaletteOnSelect(checked ? ColourData.ColourId : 0)      
        let arrr = [];
        ColorListSelected.map(e => { arrr.push(e) })
        if (checked) {
            if (arrr.indexOf(ColourData.ColourId) == -1) { arrr.push(ColourData.ColourId) }
        }
        if (arrr.length > 2) {
            arrr.shift();
        }
localStorage.setItem("colourTheme",arrr);

        setSelectedColorList(arrr)
    }, [checked])

    useEffect(() => {
      
        if (ColourData.ColourId != SelectedId) {
            setChecked((ColorListSelected.indexOf(ColourData.ColourId) > -1))
        }
    })

    return (
        <div>
            <div className="css_color_palette_controller_main">
                <div className={checked ? 'container-card-selected' : 'container-card-unselected'} onClick={(e) => { setChecked(!checked); colourPaletteOnSelect(ColourData.ColourId); }}  >
                    <div className='inGrid'>
                        <div className={classes.root}>
                            <Grid container spacing={3}>
                                <div className="css_select_toggle">  <SelectButtonControl checked={checked} hideUnCheck={true}></SelectButtonControl></div>
                                <ColourPalette ColorList={ColourData.ColorShadeListClass}></ColourPalette>
                             

                            </Grid>
                        </div>
                    </div>
                </div>
            </div>

            <CardColorPaletteDescritopm
                ColourName={ColourData.ColourName}
                DescriptionLine1={ColourData.DescritionLine1}
                DescriptionLine2={ColourData.DescritionLine2}
            ></CardColorPaletteDescritopm>
        </div>
    )
}





ColourPaletteMainController.propTypes = {
    ColourName: PropTypes.string

}

export default ColourPaletteMainController

