module.exports = [

    {
        Id: 1,
        ConcatString: 'Team',
        RowNumber:1
       

    },
    {
        Id: 2,
        ConcatString: '& Co',
        RowNumber:1

    },
    {
        Id: 3,
        ConcatString: 'Properties',
        RowNumber:2

    }, {
        Id: 4,
        ConcatString: 'Property group',
        RowNumber:2

    },
    {
        Id: 5,
        ConcatString: 'Real estate',
        RowNumber:2
    }
   
  
];