import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Checkbox from '../../components/Controllers/CheckboxComponent'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../../components/Header'
import CardFontController from '../../components/Cards/Font/CardFontController'
import images from '../../assets/varibale-list/images'
import PageData from '../../MetaData/PageContentData'
import FontTypeData from '../../MetaData/FontTypes'
import './fonttype.css'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const CardFontControllerGenrator= ({fontTypes,setFontTypes}) =>{
    if (FontTypeData.length > 0) {
        return        FontTypeData.map(function (each,index) {
            return (
                <Grid item md={4} id="fontCard">
                    <CardFontController 
                        fontData={each} 
                        fontTypes={fontTypes}
                        setFontTypes={setFontTypes}
                        ComponentKey={index}
                    />
                </Grid>)
        })
    } else {
        return []
    }
}


const FontType = ({PageId,PageNumber,setFontTypes,fontTypes}) => {

    const classes = useStyles();

    const _PageId = PageId;
    const _PageNumber=PageNumber;
    const imagelist = images;
    const numbertitle = PageData[_PageId - 1].PageNumberTittle
    const pageTitle = PageData[_PageId - 1].PageTittle
    const subtitle = PageData[_PageId - 1].Subtitle
    const nextStep= PageData[_PageId ].href


    return (
       
            <div className='container '>
                <div className='inGrid'>
                  
                            <div >
                                <div className={classes.root}>
                                    <Grid container spacing={3} >
                                    
                                   
                                    <CardFontControllerGenrator setFontTypes={setFontTypes}  fontTypes ={fontTypes}  ></CardFontControllerGenrator>
                                       
                                    
                                    </Grid>

                                </div>
                            </div>
                      
                </div>
            </div>
           
    )
}

export default FontType
