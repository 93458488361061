import React, { useState,useEffect } from 'react';
import { useMinimalSelectStyles } from '@mui-treasury/styles/select/minimal';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './drop-down-list.css'

// Original design here: https://github.com/siriwatknp/mui-treasury/issues/540



const MinimalSelect = ({  TargetAudienceData,setTargetAudienceData,targetAudience,}) => {


  const trid=targetAudience;
  const [val,setVal] = useState(eval(targetAudience));
  const [borderClass,setBorderClassVal] = useState(true);
  const handleChange = (event) => {
    setVal(event.target.value);   
  };



useEffect(() => {

  let objCurrent={...TargetAudienceData,targetAudience:val}
  setTargetAudienceData(objCurrent)
}, [val])



  const minimalSelectClasses = useMinimalSelectStyles();

  const iconComponent = (props) => {
  
    setBorderClassVal(props.className+"pz")
    return (
      <ExpandMoreIcon className={props.className + " " + minimalSelectClasses.icon}/>
    )};



  // moves the menu below the select input
  /*
  2021-07-09

      paper: minimalSelectClasses.paper,
      list: minimalSelectClasses.list,
  
  */
  const menuProps = {
    classes: {
   
    
    },
    anchorOrigin: {
      vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null
  };

  return (
    <div>     
   
    <FormControl style={{width:"100%"}} className="pz-ddl-form-control">

      {iconComponent}
    
      <Select
        disableUnderline
       // classes={{ root: minimalSelectClasses.select }}
       className={`pz-ddl-list ddl-backgroung-ico pz-${borderClass}`}
        MenuProps={menuProps}
        IconComponent={iconComponent}
               value={val>0?val:trid}
        onChange={handleChange}
      >
        
        <MenuItem value={0} disabled={true} style={{display:"none"}}>Do you specialise in a type of property?</MenuItem>
       <div className="menu-item-top-border"></div>
       <br></br>
        <MenuItem value={1}>First home buyers/sellers</MenuItem>
        <MenuItem value={2}>Investors</MenuItem>
        <MenuItem value={3}>High end</MenuItem>
        <MenuItem value={4}>Luxury</MenuItem>
        <MenuItem value={5}>Rural</MenuItem>
        <MenuItem value={6}>Lifestyle </MenuItem>
        <MenuItem value={7}>Waterfront</MenuItem>
        <MenuItem value={8}>Appartments</MenuItem>
        <MenuItem value={9}>Other</MenuItem>
        <MenuItem value={10}>No preference</MenuItem>
      
        <br></br>
      </Select>
    </FormControl>
    </div>
  );
};

export default MinimalSelect;