import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from "react-router-dom";
//import Button from '../../Button'
/*#1D252C  */
const userStyles = makeStyles({
    root: {

        backgroundColor: '#19D3C5',
        color: "black",
        fontWeight: "bold",
        padding: '5px 20px 5px 20px',
        borderRadius: 30,
        fontSize: '25px',
        fontWeight:"800",
        fontFamily :"'Nunito', 'sans-serif'",
        textTransform: "none",
        width: '220px',
        '&:hover': {
            backgroundColor: '#075E6D',
            color: '#FFF'
        }

    }
})

export const ButtonCompoment = ({ text, href, enable,data,sendData,setButtonClicked }) => {

    const classes = userStyles();
    const history = useHistory();
  


    return (
        <div className="stdButton">

            <Button
               href={enable?href:null}
               
                onClick={(e)=>{
                    if(enable){ 
                        sendData(e,data)
                    }else{
                        sendData(enable)
                    }
                    setButtonClicked(true)
                   
                }}
                className={classes.root}>
                    
                    {text}</Button>
        </div>
    )
}
// <Button  href={href} className={classes.root}>{text}</Button> 
// <Button  text={text}></Button>
export default ButtonCompoment