import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
//import Button from '../../Button'

const userStyles = makeStyles({
    root: {

      border:"1px solid black",
        color:"black",
        fontWeight:"bold",
        padding:'5px 20px 5px 20px',
        borderRadius: 30,
      
        textTransform: "none",
width:'100px',
        '&:hover': {
            backgroundColor: '#075E6D',
            color: '#FFF'
        }
       
    }
})

export const ButtonCompoment = ({text,href}) => {

    const classes = userStyles();

    return (
        <div>
       
        <Button  onClick={(e)=>{}} className={classes.root}>{text}</Button>

        
        </div>
    )
}
// <Button  href={href} className={classes.root}>{text}</Button> 
// <Button  text={text}></Button>
export default ButtonCompoment