import axios from 'axios';
import ApiList from '../ApiConfig/ApiList';


  export function saveFontTypeData(currentSaveId,email, data, setNextOnCallback, setError,setLoadStatus) {
  
    const dataForSent = data.map((k) => {
      return { id: k, state: true }
  
    })
    var obj = { email: email, data: dataForSent }  
   
    axios.post(ApiList.API_SAVE_FONT_TYPE,
      JSON.stringify(obj), {
      headers: {
        'Content-Type': 'application/json',
      }
    }
    )
      .then((response) => {
       
        if (response.data.success == true) {
          setError(false)
          setNextOnCallback(currentSaveId+1);
          setLoadStatus(false)
        } else {
          console.warn(response.data.message);      
        }
      }, (error) => {
      //  console.log(error);
        console.warn(error);      
      });
  }

  
export function getFontTypeData(email, callbackevent, setError,paramName) {
  let rtArrayData = [];
  var api_gen=require('../ApiConfig/ApiGen')(paramName,email);
  axios.get(ApiList.API_GET_FONT_TYPE + `${api_gen}`
  )
    .then((response) => { 
      if (response.data.data!=null &&  response.data.data.length > 0) {

          rtArrayData = response.data.data.map((k) => { return eval(k.id) })
      }     
      callbackevent(rtArrayData);
    }, (error) => {
      console.warn(error);
      setError(true)
    });
    return rtArrayData;
}

