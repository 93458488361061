import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import Navigator from '../components/Controllers/Navigator/Navigator'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from './Controllers/Buttons/ButtonCompoment'
import BackgroundGraphic from './BackgroundGraphic'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const Footer = ({ onAdd, PageNumber, currentLocation, nextButtonText, nextButtonHref }) => {
  const classes = useStyles();
  const location = useLocation()
  return (
    <footer>
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <div className='btnContainer'>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Navigator currentLocation={currentLocation}   PageNumber={PageNumber} />
          </Grid>
          <Grid item xs={3}>
            <div className='stepNavContainer'>
              <Button text={nextButtonText} href={nextButtonHref} />
            </div>
          </Grid>
        </Grid>
        <BackgroundGraphic/>
      </div>
    </footer>
  )
}
/*
      <div className='btnContainer'>
        {location.pathname === '/' && (
          <Button
            text={showAdd ? 'Close' : 'Add'}
            onClick={onAdd}
          />
        )}
      </div>
*/
export default Footer
