
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../components/Header'
import LogoType from '../Pages/LogoType/LogoType'
import FontType from '../Pages/FontType/FontType'
import ColourPalette from '../Pages/ColourPalette/ColourPalette'
import ColourPaletteShade from '../Pages/ColourPalette/ColourPaletteShade'
import ShapePattern from '../Pages/ShapePattern/ShapePattern'
import BrandIdentityType from '../Pages/BrandIdentityType/BrandIdentityType'
import BusinessName from '../Pages/BusinessName/BusinessName'
import TargetAudience from '../Pages/TargetAudience/TargetAudience'
import PageContentData from '../MetaData/PageContentData'
import BrandIdentityData from '../MetaData/BrandIdentityData';
import PageJSONBusinessName from '../DataTransferObjectJSON/PageJSONBusinessName';
import PageJSONTargetAudience from '../DataTransferObjectJSON/PageJSONTargetAudience'
import {  getLogoTypeData } from '../ApiService/LogoTypeDataService'
import {  getFontTypeData } from '../ApiService/FontTypeDataService'
import {  getTargetAudienceData } from '../ApiService/TargetAudienceDataService'
import {  getShapePatternData } from '../ApiService/ShapePatternDataService'
import {  getBrandIdentityData } from '../ApiService/BrandIdentityTypeDataService'
import {  getBusinessNameData } from '../ApiService/BusinessNameDataService'
import {   getColourPaletteMain, getColourShadeData } from '../ApiService/ColourPaletteDataService'
import ErrorMessage from '../components/Message/ErrorMessage'
import BandProgressBar from '../components/Controllers/ProgressBar/BandProgressBar'
import { Box, Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
   
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));



const Page = props => {
  let {
     
    defaultData,
    setLogoTypes, logoTypes,
    fontTypes, 
    currentColourShade, 
    shapeOrPattern, 
    brandIdentityType, 
    businessNameData, 
    TargetAudienceData, 
    updaloadFileList, setUploadFileList,
    ColourPaletteComment, setColourPaletteComment,
    targetAudienceFormData, 
    currentText, userEmail
  } = props;



return <div className="feedback-page-main">
       <Grid item id="gridHeaderSection">
            <Box>
              <Container>                
                <Header
                  numbertitle={PageContentData[0].PageNumberTittle}
                  title={PageContentData[0].PageTittle}
                  subtitle={PageContentData[0].Subtitle}
                />
              </Container>
            </Box>
          </Grid>
          <div className="disable-action"><LogoType  setLogoTypes={()=>{}} logoTypes={logoTypes} PageId={2} PageNumber={2}></LogoType></div>

<br></br>
<Grid item id="gridHeaderSection">
            <Box>
              <Container>                
                <Header
                  numbertitle={PageContentData[1].PageNumberTittle}
                  title={PageContentData[1].PageTittle}
                  subtitle={PageContentData[1].Subtitle}
                />
              </Container>
            </Box>
          </Grid>
          <div className="disable-action">
<FontType PageId={2} PageNumber={2} fontTypes={fontTypes} setFontTypes={()=>{}}  ></FontType>
</div>
<br></br>

<Grid item id="gridHeaderSection">
            <Box>
              <Container>                
                <Header
                  numbertitle={PageContentData[2].PageNumberTittle}
                  title={PageContentData[2].PageTittle}
                  subtitle={PageContentData[2].Subtitle}
                />
              </Container>
            </Box>
          </Grid>
          <div className="disable-action"><ColourPalette PageId={2} PageNumber={2} dataSetter={()=>{}} ColorListSelected={defaultData} ></ColourPalette></div>

<br></br>

<Grid item id="gridHeaderSection">
            <Box>
              <Container>                
                <Header
                  numbertitle={PageContentData[3].PageNumberTittle}
                  title={PageContentData[3].PageTittle}
                  subtitle={PageContentData[3].Subtitle}
                />
              </Container>
            </Box>
          </Grid>
          <div className="disable-action">
         <ColourPaletteShade      defaultData={defaultData} setColourShade={()=>{}}    currentColourShade={currentColourShade}     ColourPaletteComment={ColourPaletteComment}
     currentText={currentText}     setColourPaletteComment={setColourPaletteComment}     PageId={2} PageNumber={2}></ColourPaletteShade>
</div>
     <br></br>

     <Grid item id="gridHeaderSection">
            <Box>
              <Container>                
                <Header
                  numbertitle={PageContentData[4].PageNumberTittle}
                  title={PageContentData[4].PageTittle}
                  subtitle={PageContentData[4].Subtitle}
                />
              </Container>
            </Box>
          </Grid>
          <div className="disable-action"><ShapePattern     shapeOrPattern={shapeOrPattern}   
            setShapeOrPattern={()=>{}} 
                PageId={2} PageNumber={2}></ShapePattern></div>

<br></br>
<Grid item id="gridHeaderSection">
            <Box>
              <Container>                
                <Header
                  numbertitle={PageContentData[5].PageNumberTittle}
                  title={PageContentData[5].PageTittle}
                  subtitle={PageContentData[5].Subtitle}
                />
              </Container>
            </Box>
          </Grid>     
            <div className="disable-action">
<BrandIdentityType     brandIdentityType={brandIdentityType}     setBrandIdentityType={()=>{}}     PageId={2} PageNumber={2}></BrandIdentityType>
</div>
<br></br>
<Grid item id="gridHeaderSection">
            <Box>
              <Container>                
                <Header
                  numbertitle={PageContentData[6].PageNumberTittle}
                  title={PageContentData[6].PageTittle}
                  subtitle={PageContentData[6].Subtitle}
                />
              </Container>
            </Box>
          </Grid>
          <div className="disable-action">         
<BusinessName     businessNameData={businessNameData}     setBusinessNameData={()=>{}}
     PageId={2} PageNumber={2}></BusinessName>
     
     </div>

     <Grid item id="gridHeaderSection">
            <Box>
              <Container>                
                <Header
                  numbertitle={PageContentData[7].PageNumberTittle}
                  title={PageContentData[7].PageTittle}
                  subtitle={PageContentData[7].Subtitle}
                />
              </Container>
            </Box>
          </Grid> 


     <div >     
     <TargetAudience     TargetAudienceData={TargetAudienceData}     setTargetAudienceData={()=>{}}     updaloadFileList={updaloadFileList}
     setUploadFileList={setUploadFileList}     targetAudienceFormData={targetAudienceFormData}     setTargetAudienceFormData={()=>{}}     userEmail={userEmail}
     paramName='id'
     PageId={2} PageNumber={2}  isDisableAction={true}></TargetAudience>
     </div>
</div> 
};
const MasterPage = (props) => {

  const classes = useStyles();
  const [currentIndex, setNext] = useState(1)
  const [colourSelection, setPaletteColour] = useState([]);
  const [logoTypes, setLogoTypes] = useState([]);
  const [fontTypes, setFontTypes] = useState([]);
  const [colourShadeSelection, setColourShade] = useState(['org1', 'org3']);
  const [shapeOrPattern, setShapeOrPattern] = useState([]);
  // const [brandIdentityType, setBrandIdentityType] = useState([]);
  const [data, setData] = useState(BrandIdentityData);
  const [businessNameData, setBusinessNameData] = useState(PageJSONBusinessName)
  const [TargetAudienceData, setTargetAudienceData] = useState(PageJSONTargetAudience)
  const [updaloadFileList, setUploadFileList] = useState([])
  const [isErrorInSave, setError] = useState(false)
  const [isLoading, setLoadStatus] = useState(true)
  const [ColourPaletteComment, setColourPaletteComment] = useState('')
  const [targetAudienceFormData, setTargetAudienceFormData] = useState();
  const user_email = props.match.params.id; //localStorage.getItem("emailid");
  const currentText = ColourPaletteComment;
  const DiableApiValidation = true;



useEffect(() => {
  // Run! Like go get some data from an API.

  Promise.all([
    setLoadStatus(true  ),
    getLogoTypeData(user_email, setLogoTypes, setError,'id'),
    getFontTypeData(user_email, setFontTypes, setError,'id'),
    getColourPaletteMain(user_email, setPaletteColour, setError,'id'),
    getColourShadeData(user_email, setColourShade, setColourPaletteComment, setError,'id'),
    getShapePatternData(user_email, setShapeOrPattern, setError,'id'),
    getBrandIdentityData(user_email, setData, setError,'id'),
    getBusinessNameData(user_email, setBusinessNameData, setError,'id'),
    getTargetAudienceData(user_email, setTargetAudienceData, setError,'id'),
    setLoadStatus(false  )

  ]).catch(err=>{

    console.warn(err);
  })



}, []);


 

    return (

  !isLoading && 

      <div>
        <BandProgressBar isLoading={isLoading}></BandProgressBar>
        <ErrorMessage isError={isErrorInSave}></ErrorMessage>
        <Grid id="gridTopLevel" className={classes.root}>         
          <Grid item className="page-box-container">
            <Box style={{height: "inherit" }} >
              <Container>
                <Page
                                   setPaletteColour={setPaletteColour}
                  defaultData={colourSelection}             
                  logoTypes={logoTypes}
                  fontTypes={fontTypes}                     
                  currentColourShade={colourShadeSelection}
                  shapeOrPattern={shapeOrPattern}              
                  brandIdentityType={data}         
                  businessNameData={businessNameData}         
                  TargetAudienceData={TargetAudienceData}           
                  updaloadFileList={updaloadFileList}              
                  ColourPaletteComment={ColourPaletteComment}            
                  currentText={currentText}
                  targetAudienceFormData={targetAudienceFormData}               
                  userEmail={user_email}
                >



                </Page>

              </Container>
            </Box>
          </Grid>
  
        </Grid>
         
       
      </div>




    )





}





export default MasterPage
