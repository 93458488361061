module.exports = function (api_type, parameter_value) {
/*

Return the API parameter based on retriving senario ( User Email /  User Id)

*/
    let returnValue;
    if (api_type === 'email') {

        returnValue = `?findBy=email&email=${parameter_value}`;
    }

    if (api_type === 'id') {

        returnValue = `?findBy=id&id=${parameter_value}`;
    }

    return returnValue;
}