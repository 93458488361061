import axios from "axios";
import ApiList from "../ApiConfig/ApiList";




  

export function saveShapePatternData(currentSaveId,email, data, setNextOnCallback, setError, setLoadStatus) {
     const dataForSent = data.map((k) => {
      return { id: k, state: true }
    })
    var obj = { email: email, data: dataForSent }
  axios.post(ApiList.API_SAVE_SHAPE_PATTERN,
    JSON.stringify(obj), {
    headers: {
      'Content-Type': 'application/json',
    }
  }
  )
    .then((response) => {    
      if (response.data.success == true) {
        setError(false)
        setLoadStatus(false)
        setNextOnCallback(currentSaveId + 1);
      } else {
        console.warn(response.data.message);
      }



    }, (error) => {
      console.warn(error);
      setError(true)

    });
}


export function getShapePatternData(email, callbackevent, setError,paramName) {
 
  let rtArrayData = [];
  var api_gen=require('../ApiConfig/ApiGen')(paramName,email);
  axios.get(ApiList.API_GET_SHAPE_PATTERN + `${api_gen}`

  )
    .then((response) => { 
      if (response.data.data!=null &&  response.data.data.length > 0) {
        rtArrayData = response.data.data.map((k) => { return eval(k.id) })
      }    
      setError(false)
      callbackevent(rtArrayData);
    }, (error) => {
      console.warn(error);
      setError(true)

    });

    return rtArrayData;
}
