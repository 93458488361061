import axios from 'axios';
import ApiList from '../ApiConfig/ApiList';


export  async function saveUserData(data, setGetStarted) {
  axios.post(ApiList.API_SAVE_EMAIL, {
    email: data
  })
    .then((response) => {     
      setGetStarted(true);
    }, (error) => {
      console.warn(error);
    });
}


