module.exports = [

    {
        ElementId: 1,
        FontName: "Circles",
        FontStyleClassName: "",
        SubtitleLeft: "Confidence",
        ImageClass1: "/img/shapesPatternsIcons/Ownly_Shape_Circle.svg",
        ImageClass2: "/img/shapesPatternsIcons/Ownly_Shape_Circle_1.svg",
        ImageClass3: "/img/shapesPatternsIcons/Ownly_Shape_Circle_2.svg",
        ElementValue:50,
        OverViewDescription: "Infinity & protection"
    },
    {
        ElementId: 2,
        FontName: "Squares",
        FontStyleClassName: "",
        SubtitleLeft: "Confidence",
        ImageClass1: "/img/shapesPatternsIcons/Ownly_Shape_Square-01.svg",
        ImageClass2: "/img/shapesPatternsIcons/Ownly_Shape_Square_1-01.svg",
        ImageClass3: "/img/shapesPatternsIcons/Ownly_Shape_Square_2-01.svg",
        ElementValue:50,
        OverViewDescription: "Balance & traditional"
    },

    {
        ElementId: 3,
        FontName: "Triangles",
        FontStyleClassName: "",
        SubtitleLeft: "Confidence",
        ImageClass1: "/img/shapesPatternsIcons/Ownly_Shape_Triangle-01.svg",
        ImageClass2: "/img/shapesPatternsIcons/Ownly_Shape_Triangle_1.svg",
        ImageClass3: "/img/shapesPatternsIcons/Ownly_Shape_Triangle_2.svg",
        ElementValue:50,
        OverViewDescription: "Stability & energy"
    },

    {
        ElementId: 4,
        FontName: "Lines",
        FontStyleClassName: "",
        SubtitleLeft: "Confidence",
        ImageClass1: "/img/shapesPatternsIcons/Ownly_Shape_Line-01.svg",
        ImageClass2: "/img/shapesPatternsIcons/Ownly_Shape_Lines_1-01.svg",
        ImageClass3: "/img/shapesPatternsIcons/Ownly_Shape_Lines_2-01.svg",
        ElementValue:50,
        OverViewDescription: "Order & trust"
    },

    {
        ElementId: 5,
        FontName: "Rhombus",
        FontStyleClassName: "",
        SubtitleLeft: "Confidence",
        ImageClass1: "/img/shapesPatternsIcons/Ownly_Shape_Rhoubus_1-01.svg",
        ImageClass2: "/img/shapesPatternsIcons/Ownly_Shape_Rhoubus_2-01.svg",
        ImageClass3: "/img/shapesPatternsIcons/Ownly_Shape_Rhoubus_3-01.svg",
        ElementValue:50,
        OverViewDescription: "Vibrant & contemporary"
    },

    {
        ElementId: 6,
        FontName: "Curves",
        FontStyleClassName: "",
        ImageClass1: "/img/shapesPatternsIcons/Ownly_Shape_Curve_1-01.svg",
        ImageClass2: "/img/shapesPatternsIcons/Ownly_Shape_Curve_2-01.svg",
        ImageClass3: "/img/shapesPatternsIcons/Ownly_Shape_Curve_3-01.svg",
        ElementValue:50,
        OverViewDescription: "Care & support"
    }
    


  
];