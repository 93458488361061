import React, { useState, useEffect } from 'react'
import { Checkbox } from '@material-ui/core'
import CheckedIcon from '../../../assets/images/Ownly_Selected-01.svg'
import UnCheckedIcon from '../../../assets/images/Ownly_Unselected-01.svg'


const Results = ({ width, height }) => (
    <div id="results" className="search-results">
        <img className="logo" src={CheckedIcon}  ></img>
    </div>
)

const Results_Unselected = ({ width, height }) => (
    <div id="results" className="search-results">
        <img className="logo" src={UnCheckedIcon} style={{ width: width, height: height }} ></img>
    </div>
)


const SelectButtonControl = ({ businessNameData, setBusinessNameData,isDefaultChecked
     }) => {
    const [checked, setChecked] = React.useState(isDefaultChecked)
    //





useEffect(() => {
  
    let objCurrent={...businessNameData, IsNamePartOfBusinessName:checked}

    setBusinessNameData(objCurrent);
   
}, [checked])


    return (
        <div >
                 <div style={{ display: "none" }}> <Checkbox
                checked={checked}
            >
            </Checkbox   ></div>
            <div onClick={(e) => { setChecked(!checked) }}


            >    {isDefaultChecked ? <Results width="65px" /> : <Results_Unselected width="65px" />}</div>

        </div>
    )
}


SelectButtonControl.defaultProps = {
   

}



export default SelectButtonControl
