import './error-msg.css'

const ErrorMessageContainer = () => {
    return (
        <div className="alert-popup">
           	<div className="alert error">   
                <p className="inner">
                    <strong>Error!</strong> The server is not responding, try again later.
                </p>
            </div>
        </div>
    )
}

const ErrorMessage = ({ isError }) => {

    if (isError)
        return <ErrorMessageContainer></ErrorMessageContainer>
    else
        return []

}



export default ErrorMessage;