import imageIcon1 from '../../assets/images/LogoTypes/Ownly_Logo_11-01.svg'
import imageIcon2 from '../../assets/images/LogoTypes/Ownly_Logo_10-01.svg'
import imageIcon3 from '../../assets/images/LogoTypes/Ownly_Logo_12-01.svg'
import imageIcon4 from '../../assets/images/LogoTypes/Ownly_Logo_9-01.svg'

import imageIcon5 from '../../assets/images/LogoTypes/Ownly_Logo_8-01.svg'
import imageIcon6 from '../../assets/images/LogoTypes/Ownly_Logo_7-01.svg'
import imageIcon7 from '../../assets/images/LogoTypes/Ownly_Logo_6-01.svg'
import imageIcon8 from '../../assets/images/LogoTypes/Ownly_Logo_5-01.svg'



import imageIcon9 from '../../assets/images/LogoTypes/Ownly_Logo_4-01.svg'
import imageIcon10 from '../../assets/images/LogoTypes/Ownly_Logo_3-01.svg'
import imageIcon11 from '../../assets/images/LogoTypes/Ownly_Logo_2-01.svg'
import imageIcon12 from '../../assets/images/LogoTypes/Ownly_Logo_1-01.svg'

/*

import imageIcon9 from '../../assets/images/LogoTypes/Ownly_Logo_4-01.svg'
import imageIcon10 from '../../assets/images/LogoTypes/Ownly_Logo_3-01.svg'
import imageIcon11 from '../../assets/images/LogoTypes/Ownly_Logo_1-01.svg'
import imageIcon12 from '../../assets/images/LogoTypes/Ownly_Logo_2-01.svg'
*/




const ImageListData = [

    {
        imageIcon1: imageIcon1,
        imageIcon2:imageIcon2,
        imageIcon3:imageIcon3,
        imageIcon4:imageIcon4
    },
    {
        imageIcon1: imageIcon5,
        imageIcon2:imageIcon6,
        imageIcon3:imageIcon7,
        imageIcon4:imageIcon8
      
    },
    {
        imageIcon1: imageIcon9,
        imageIcon2:imageIcon10,
        imageIcon3:imageIcon11,
        imageIcon4:imageIcon12
        
      
    }
   
];

export default ImageListData;