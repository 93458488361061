import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { red } from '@material-ui/core/colors';


const GreenCheckbox = withStyles({
    root: {
        fontFamily :"'Nunito', 'sans-serif'",
        color: "#44FFD1",
        '&$checked': {
            color: "#44FFD1",
        },
    },
    checked: {},

})((props) => <Checkbox color="default" {...props} />);

const CheckBoxStyle = { color: '#44FFD1' }


function CheckBoxStartPage({ text ,name,setCondition}) {
    return (
        <div>
            <FormControlLabel
            onChange={(e)=>{setCondition(e.target.checked)}}
                control={<GreenCheckbox name={name}  />}
               label={text}          
            />    
        </div>
    )
}

const CheckboxComponent = ({ text,setCondition }) => {
    return (
        <CheckBoxStartPage text={text}     setCondition={setCondition}></CheckBoxStartPage>
    )
}

export default CheckboxComponent
