import PropTypes from 'prop-types'


const BackgroundGraphic = () => {


  return (
    <div>  
    
      <div className="rotate-container">
        <div className="rotate-inner rotate">

        </div>
      </div>

    </div>

  )
}

/*
  <div className='backgroundGraphic rotate'>
      </div>
*/

export default BackgroundGraphic
