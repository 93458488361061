import axios from "axios";
import ApiList from "../ApiConfig/ApiList";


export  function  saveTargetAudienceData(currentSaveId, TargetAudienceData, setNext, setError,email) {

  const formData = new FormData();
  formData.append("aboutYou", TargetAudienceData.aboutYou);
  formData.append("email", email);
  formData.append("comment", TargetAudienceData.comment);
  formData.append("targetAudience", TargetAudienceData.targetAudience);
  formData.append("imageURL", TargetAudienceData.imageURL);


  const config = {
    headers: {
      'content-type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  }
    axios
    .post(ApiList.API_SAVE_TARGET_AUDIENCE, formData, config)
    .then((response) => {
      if (response.data != undefined && response.data.success == true) {
        setError(false)
        setNext(currentSaveId + 1);
      } else {
       // setError(true)
      }
    }, (error) => {
      console.log(error);
      setError(true);
    });
}

export function submitForm(email,TargetAudienceData, selectedFile, setFileList) {
  const formData = new FormData();
  formData.append("aboutYou", (TargetAudienceData.aboutYou==undefined?'':TargetAudienceData.aboutYou));
  formData.append("email", email);
  for (let index = 0; index < selectedFile.length; index++) {
    formData.append("files", selectedFile[index]);
  }
  formData.append("comment", (TargetAudienceData.comment==undefined?'':TargetAudienceData.comment));
  formData.append("targetAudience", (TargetAudienceData.targetAudience==undefined?0:TargetAudienceData.targetAudience));
  formData.append("imageURL",(TargetAudienceData.imageURL==undefined?'':TargetAudienceData.imageURL) );
  saveTargetAudienceDataFileUpload(formData,email,setFileList);
};


 function  saveTargetAudienceDataFileUpload( formData,email,setFileList) {
  const config = {
    headers: {
      'content-type': 'multipart/form-data; boundary=<calculated when request is sent>'
    }
  }
  axios
    .post(ApiList.API_SAVE_TARGET_AUDIENCE, formData, config)
    .then((response) => {
      if (response.data != undefined && response.data.success == true) {
   setFileList(response.data.files);      
      } else {
        console.warn(response);
      }
    }, (error) => {
  
      console.warn(error);
     
    });
}



export function getTargetAudienceData(email, callbackevent, setError,paramName) {
  var api_gen=require('../ApiConfig/ApiGen')(paramName,email);
  axios.get(ApiList.API_GET_TARGET_AUDIENCE + `${api_gen}`

  )
    .then((response) => {
          if (response.data.data!=null) { 
                      callbackevent(response.data.data);
      }   
    }, (error) => {
      console.warn(error);
      setError(true)
    });   
}


export function deleteImageFile(email,fileName, fileID,callbackevent, setError,fileList,updaloadFileList,index,IsDeleteFromServer,paramName) {
  let rtArrayData = [];

if(IsDeleteFromServer){

 // callbackevent(fileList.filter((k, i) => { return i != index }));
//  setError(updaloadFileList.filter((k) => { return k.FileName !=fileName }))

}else{


  var api_gen=require('../ApiConfig/ApiGen')(paramName,email);

  axios.patch(ApiList.API_DELETE_TARGET_AUDIENCE_IMAGE + `${api_gen}&id=${fileID}`

  )
  .then((response) => {
    
    if (response.data != undefined && response.data.success == true) {
      callbackevent(fileList.filter((k, i) => { return i != index })); 
    } 
  }, (error) => {
    console.warn(error);
     setError(true)

  });
}

    return rtArrayData;
}