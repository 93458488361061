import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import SelectButtonControl from '../../Controllers/SelectButton/SelectButtonControl'
import Grid from '@material-ui/core/Grid';
import './CardShapePatternController.css'
import './shape-pattern.css'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

function CardShapePatternController({ cardDataSource, shapeOrPattern, setShapeOrPattern, elementId }) {

    const [checked, setChecked] = React.useState(shapeOrPattern.indexOf(eval(elementId)) > -1)
    const classes = useStyles();
const IsDefaultCheck=(shapeOrPattern.indexOf(eval(elementId)) > -1);


    useEffect(() => {
        let currentArray = [];

        shapeOrPattern.map((e) => { currentArray.push(e) })
        if (checked) {
            currentArray.push(elementId);

        } else {

            currentArray = currentArray.filter(k => (k != elementId));

        }
        setShapeOrPattern(currentArray);
   
    }, [checked])


    return (
        <div className="css_shape_pattern_controller_main">

            <div className={IsDefaultCheck ? 'container-card-selected' : 'container-card-unselected'} onClick={() => { setChecked(!checked) }}  >
                <div className='inGrid'>
                    <div className={classes.root}>     
                    <Grid container spacing={3}>
                        <div className="css_select_toggle">  <SelectButtonControl checked={IsDefaultCheck}></SelectButtonControl></div>
                        <Grid item xs={12} ></Grid>
                        <Grid item xs={12} ></Grid>
                            <Grid item xs={1} ></Grid>
                            <Grid item xs={10}>
                        
                                <Grid item xs={4}  id="shapeImgCon">
                                    
                                    <img className="shape_image" src={cardDataSource.ImageClass1}  ></img>
                                    
                                </Grid>
                                <Grid item xs={4}  id="shapeImgCon">
                                    
                                    <img className="shape_image" src={cardDataSource.ImageClass2}  ></img>
                                    
                                </Grid>
                                <Grid item xs={4} id="shapeImgCon">
                                    
                                    <img className="shape_image" src={cardDataSource.ImageClass3}  ></img>
                                    
                                </Grid>
                        
                            </Grid>
                            <Grid item xs={1} ></Grid>
                        <Grid item xs={12} style={{ textAlign: "center", verticalAlign: "text-bottom", padding: 0 }}>

                            <div className={`${cardDataSource.FontStyleClassName} card-header`}  >
                                {cardDataSource.FontName}
                            </div>
                        </Grid>


                        <Grid item xs={12} className="css_overview_description" style={{ textAlign: "center", verticalAlign: "text-bottom" }}>
                            <span className="css_overview_caption">{cardDataSource.OverViewCaption} </span>      {cardDataSource.OverViewDescription}
                        </Grid>

                    </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}





CardShapePatternController.propTypes = {
    cardDataSource: PropTypes.object,
    shapeOrPattern: PropTypes.array

}

export default CardShapePatternController

