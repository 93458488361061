module.exports = [

    {
        PageId: 1,
        PageNumber: 1,
        PageNumberTittle: "1. CHOOSE YOUR",
        PageTittle: "Logo Type.",
        Subtitle: "You can select as many as you like.",
        href: "/logotype"
    },

    {
        PageId: 2,
        PageNumber: 2,
        PageNumberTittle: "2. CHOOSE YOUR",
        PageTittle: "Font Type.",
        Subtitle: "You can select as many as you like.",
        href: "/fonttype"
    }
    ,

    {
        PageId: 3,
        PageNumber: 3,
        PageNumberTittle: "2. CHOOSE YOUR",
        PageTittle: "Colour Palette.",
        Subtitle: "Select one main colour and one alternative colour",
        href: "/colour-palette"
    },
    
    {
        PageId: 4,
        PageNumber: 3,
        PageNumberTittle: "3. CHOOSE YOUR",
        PageTittle: "Colour Palette.",
        Subtitle: "Select one shade from each colour range",
        href: "/colour-palette-shade"
    }
    ,

    {
        PageId: 5,
        PageNumber: 4,
        PageNumberTittle: "4. CHOOSE YOUR",
        PageTittle: "Shape or pattern.",
        Subtitle: "Select a main shape that would best represent the look and feel of your brand.",
        href: "/shape-pattern"
    }
    ,

    {
        PageId: 6,
        PageNumber: 5,
        PageNumberTittle: "2. CHOOSE YOUR",
        PageTittle: "Brand Identity Type.",
        Subtitle: "Move the point on the slider to indicate what you would prefer.",
        href: "/brand-identity-type"
    }
    ,

    {
        PageId: 7,
        PageNumber: 6,
        PageNumberTittle: "6. TELL US ABOUT YOUR",
        PageTittle: "Business Name.",
        Subtitle: "",
        href: "/business-name"
    }
    ,

    {
        PageId: 8,
        PageNumber: 7,
        PageNumberTittle: "7. TELL US ABOUT YOUR",
        PageTittle: "Target Audience.",
        Subtitle: "",
        href: "/target-audience"
    }
    ,

    {
        PageId: 9,
        PageNumber: 7,
        PageNumberTittle: "end",
        PageTittle: "",
        Subtitle: "",
        href: "/completed"
    }
   
];