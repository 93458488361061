import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import SelectButtonControl from '../../Controllers/SelectButton/SelectButtonControl'
import Grid from '@material-ui/core/Grid';
import './CardController.css'
import { makeStyles } from '@material-ui/core/styles';

//import '../../assets/images/LgoTypes'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

function CardController({  header, description, imageIcon1,
    imageIcon2,
    imageIcon3,
    imageIcon4, logoTypeId, logoTypes, setLogoTypes}) {
        let mylow=[];
        logoTypes.map((e) => { mylow.push(e) })
       // const isDefaultTrue=(logoTypes.indexOf(eval(logoTypeId))>-1);
        const [checked, setChecked] = React.useState(mylow.indexOf(logoTypeId)>-1)
        const classes = useStyles();
    
   
    
        useEffect(() => {
            let localArray = [];
            logoTypes.map((e) => { localArray.push(e) })
            if (checked) {
                localArray.push(logoTypeId);
            } else {
                localArray = localArray.filter(k => (k != logoTypeId));
            }
            setLogoTypes(localArray);
        }, [checked])


const isDefaultTrue=(logoTypes.indexOf(logoTypeId)>-1)

    return (
        <div className="css_logo_controller_main"> 
<div className={isDefaultTrue ? 'container-card-selected' : 'container-card-unselected'} onClick={() => { setChecked(!checked) }}  >
                <div className='inGrid'>
                    <div className={classes.root}>     <Grid container spacing={3}>
                        <Grid item xs={12} style={{ textAlign: "center", verticalAlign: "text-bottom" }}>


                            <div className="card-header" >
                                {header}
                            </div>
                        </Grid>
                        <div className="css_select_toggle">  <SelectButtonControl checked={isDefaultTrue}></SelectButtonControl></div>
                        <Grid item xs={12} style={{ textAlign: "center", verticalAlign: "text-bottom" }}>
                            <div className="card-descriprion" >
                                {description}
                            </div>
                        </Grid>
                        <Grid item xs={1} id="hideMobile" >     </Grid>
                        <Grid item sm={5} xs={6} style={{ padding: "0" }}>
                            <img className="logo_image" src={imageIcon1}  ></img>
                        </Grid>
                        <Grid item sm={5} xs={6} style={{ padding: "0" }}>
                            <img className="logo_image" src={imageIcon2}  ></img>
                        </Grid>
                        <Grid item xs={1} id="hideMobile">     </Grid>
                        <Grid item xs={1} id="hideMobile">     </Grid>
                        <Grid item sm={5} xs={6} style={{ padding: "0" }}>
                            <img className="logo_image" src={imageIcon3}  ></img>
                        </Grid>
                        <Grid item sm={5} xs={6} style={{ padding: "0" }}>
                            <img className="logo_image" src={imageIcon4}  ></img>
                        </Grid>
                        <Grid item xs={1} id="hideMobile">     </Grid>
                        <Grid item xs={12} >     </Grid>
                        <Grid item xs={12} >     </Grid>
                    </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}





export default CardController

