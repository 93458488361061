import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import './slider.css'

const useStyles = makeStyles({
  root: {   
    padding:'15px'
  },
});

export default function ContinuousSlider({ leftString, rightString,    businessNameData,  setBusinessNameData,BrandAgentSliderValue}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(BrandAgentSliderValue==undefined?50:BrandAgentSliderValue);
  const handleChange = (event, newValue) => {
    setValue(newValue);  
  
  };
  
  useEffect(() => {
    let objCurrent={...businessNameData,BrandAgentSliderValue:value}
    setBusinessNameData(objCurrent)
  }, [value])

  return (
    <div className={classes.root}>
     <Grid item md={12}></Grid>
      <Grid container spacing={4} className="pz-slider-container">
        <Grid item xs={3}  className="slider_text smpadding">
      {leftString}
        </Grid>
        <Grid item xs={6} spacing={0}>
        
                  <Slider value={BrandAgentSliderValue?BrandAgentSliderValue:50} onChange={handleChange} aria-labelledby="continuous-slider" />
        </Grid>
        <Grid item xs={3}   className="slider_text">
      {rightString}
        </Grid>
      </Grid>
      <Grid item md={12}></Grid>
    </div>
  );
}
