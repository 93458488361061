import React from 'react';
import ReactDOM from 'react-dom';
import MetaTagsServer from './MetaTags/MetaTag';
import {MetaTagsContext} from 'react-meta-tags';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import meta from './MetaTags/MetaTag'

ReactDOM.render(
  <React.StrictMode>

 <App />

   
  </React.StrictMode>,
  document.getElementById('root')
);
////    <App />


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



