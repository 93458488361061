import { useState } from 'react'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CardController from '../../components/Cards/Logo/CardController'
import images from '../../assets/varibale-list/images'
import imageIcon1 from '../../assets/images/LogoTypes/Ownly_Logo_9-01.svg'
import imageIcon2 from '../../assets/images/LogoTypes/Ownly_Logo_10-01.svg'
import imageIcon3 from '../../assets/images/LogoTypes/Ownly_Logo_11-01.svg'
import imageIcon4 from '../../assets/images/LogoTypes/Ownly_Logo_12-01.svg'
import LogoTypeData from '../../MetaData/LogoTypesData'
import './logotype.css'

import ImageListData from './LogoImageList'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const CardLogoControllerGenrator = ({ imagelist, setLogoTypes
    , logoTypes, dataSource

}) => {

   
    if (dataSource.length > 0) {
        return dataSource.map(function (each, index) {      
            return (           

                <Grid  item md={4} sm={12} >
                 
                    <CardController icon={imagelist}
                        key={each.Id}
                        logoTypeId={each.Id}
                        setLogoTypes={setLogoTypes}
                        logoTypes={logoTypes}
                        header={each.header}
                        description={each.description}                     
                        imageIcon1={ImageListData[index].imageIcon1}
                        imageIcon2={ImageListData[index].imageIcon2}
                        imageIcon3={ImageListData[index].imageIcon3}
                        imageIcon4={ImageListData[index].imageIcon4}
                    ></CardController>
                </Grid>


            )
        })
    } else {
        return []
    }
}





const LogoType = ({ PageId,  setLogoTypes, logoTypes }) => {

  
    const classes = useStyles();
    const imagelist = images;
    const [DataforTemplate, setTemplateData] = useState(LogoTypeData)

    return (

        <div className='container'>
            <div className='inGrid'>
                <div >
                    <div className={classes.root}>
                        <Grid container spacing={3}>

                            <CardLogoControllerGenrator
                                imagelist={imagelist}
                                setLogoTypes={setLogoTypes}
                                logoTypes={logoTypes}
                                dataSource={DataforTemplate}
                            >
                            </CardLogoControllerGenrator>
                        </Grid>
                        
                    </div>
                </div>

            </div>
        </div>
    )
}
export default LogoType
