
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
import './comment-text-area.css'
import FontTypes from '../../../MetaData/FontTypes';

const userStyles = makeStyles({
    root: {

        backgroundColor: 'white',
        borderRadius: 20,
        border: 0,
        width: '100%',

        fontFamily :"'Nunito', 'sans-serif'",
    }
})


export const CommentTextArea = ({ label, sublabel, placeholder, height, currentText, UpdateField, TargetAudienceData, setTargetAudienceData }) => {
    const classes = userStyles();
    const fromCurrentText=currentText;
    const [text, setText] = React.useState(currentText)
    const [checked, setChecked] = React.useState(true)
 
    const background_icon = (text.length>0?null:"textarea-backgroung-ico")
    useEffect(() => {
        let objCurrent = { ...TargetAudienceData, [UpdateField]: text }
        setTargetAudienceData(objCurrent)
        setChecked(false)
    }, [text])


    return (
        <div>
             <div >
                        <Grid className={classes.root} container spacing={0} >
                    <Grid item md={12} sm={12} xs={12} spacing={0} >
                        <div className="description-label"> {label} <span>{sublabel}</span> </div>
                    </Grid>
                    
      
                    <Grid item md={12}  sm={12} xs={12} spacing={0} style={{paddingTop:"15px",paddingBottom:"15px"}}>

                        <textarea
                            className={background_icon} onChange={(e) => { setText(e.target.value) }}

value={text?text: fromCurrentText}
                            style={{ width: "100%", height: `${height}` }}
                            placeholder={placeholder}></textarea>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default CommentTextArea
