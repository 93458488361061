import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';
import './slider.css'
import BranTypeData from '../../../MetaData/BrandIdentityData'

const useStyles = makeStyles({
  root: {

    padding: '15px'
  },
});

export default function ContinuousSlider({ sliderId, leftString, rightString, brandIdentityType, setBrandIdentityType }) {
  const classes = useStyles();
  let brobj = brandIdentityType.filter((k) => (k.id == sliderId));
  const sliderDefaultVal = (brobj.length > 0 ? brobj[0].value : 50);  
  const [sliderValue, setValue] = React.useState((brobj.length > 0 ? brobj[0].value : 50)); 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {

    let dataArray = [];
    BranTypeData.map((k) => {
      if (k.Id == sliderId) {
k.value=sliderValue;

      }
    }
    )

    

    dataArray= BranTypeData.map((k)=>{return {id:k.Id,value:k.value}})
    setBrandIdentityType(dataArray) 

  }, [sliderValue])





  return (
    <div className={classes.root}>
      <Grid item md={12}   xs={12}></Grid>
      <Grid container spacing={2} className="pz-slider-container">
        <Grid item md={3} xs={2} className="slider_text slider_textL">
          <p>{leftString}</p>
        </Grid>
        <Grid item md={6} xs={8} spacing={0}>         
          <Slider value={(sliderDefaultVal==50?sliderValue:sliderDefaultVal)} onChange={handleChange} aria-labelledby="continuous-slider" />
        </Grid>
        <Grid item md={3} xs={2} className="slider_text slider_textR">
          <p>{rightString}</p>
        </Grid>
      </Grid>
      <Grid item md={12}></Grid>
    </div>
  );
}
