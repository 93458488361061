
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import BackgroundGraphic from '../components/BackgroundGraphic'
import Header from '../components/Header'
import Footer from '../components/Footer/Footer'
import LogoType from '../Pages/LogoType/LogoType'
import FontType from '../Pages/FontType/FontType'
import ColourPalette from '../Pages/ColourPalette/ColourPalette'
import ColourPaletteShade from '../Pages/ColourPalette/ColourPaletteShade'
import ShapePattern from '../Pages/ShapePattern/ShapePattern'
import BrandIdentityType from '../Pages/BrandIdentityType/BrandIdentityType'
import BusinessName from '../Pages/BusinessName/BusinessName'
import TargetAudience from '../Pages/TargetAudience/TargetAudience'
import EndPage from '../Pages/EndPage/EndPage'
import PageContentData from '../MetaData/PageContentData'
import BrandIdentityData from '../MetaData/BrandIdentityData';
import PageJSONBusinessName from '../DataTransferObjectJSON/PageJSONBusinessName';
import PageJSONTargetAudience from '../DataTransferObjectJSON/PageJSONTargetAudience'
import { saveLogoTypeData, getLogoTypeData } from '../ApiService/LogoTypeDataService'
import { saveFontTypeData, getFontTypeData } from '../ApiService/FontTypeDataService'
import { saveTargetAudienceData, getTargetAudienceData } from '../ApiService/TargetAudienceDataService'
import { saveShapePatternData, getShapePatternData } from '../ApiService/ShapePatternDataService'
import { saveBrandIdentityData, getBrandIdentityData } from '../ApiService/BrandIdentityTypeDataService'
import { saveBusinessNameData, getBusinessNameData } from '../ApiService/BusinessNameDataService'
import { saveColourPaletteShade, saveColourPaletteMain, getColourPaletteMain, getColourShadeData } from '../ApiService/ColourPaletteDataService'
import ErrorMessage from '../components/Message/ErrorMessage'
import BandProgressBar from '../components/Controllers/ProgressBar/BandProgressBar'
import { Box, Container } from '@material-ui/core';
import {setWindowHeight} from '../components/Footer/FooterResize'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));



const Page = props => {
  let {
    PageId, setPaletteColour,
    defaultData,
    setLogoTypes, logoTypes,
    fontTypes, setFontTypes,
    currentColourShade, setColourShade,
    shapeOrPattern, setShapeOrPattern,
    brandIdentityType, setBrandIdentityType,
    businessNameData, setBusinessNameData,
    TargetAudienceData, setTargetAudienceData,
    updaloadFileList, setUploadFileList,
    ColourPaletteComment, setColourPaletteComment,
    targetAudienceFormData, setTargetAudienceFormData,
    currentText, userEmail
  } = props;

  if (PageId == 1) {
    return <LogoType setLogoTypes={setLogoTypes} logoTypes={logoTypes} PageId={2} PageNumber={2}></LogoType>
  } else if (PageId == 2) {
    return <FontType PageId={2} PageNumber={2} fontTypes={fontTypes} setFontTypes={setFontTypes}  ></FontType>
  } else if (PageId == 3) {
    return <ColourPalette PageId={2} PageNumber={2} dataSetter={setPaletteColour} ColorListSelected={defaultData} ></ColourPalette>
  } else if (PageId == 4) {
    return <ColourPaletteShade
      defaultData={defaultData} setColourShade={setColourShade}
      currentColourShade={currentColourShade}
      ColourPaletteComment={ColourPaletteComment}
      currentText={currentText}
      setColourPaletteComment={setColourPaletteComment}

      PageId={2} PageNumber={2}></ColourPaletteShade>
  } else if (PageId == 5) {
    return <ShapePattern
      shapeOrPattern={shapeOrPattern}
      setShapeOrPattern={setShapeOrPattern}


      PageId={2} PageNumber={2}></ShapePattern>
  } else if (PageId == 6) {
    return <BrandIdentityType
      brandIdentityType={brandIdentityType}
      setBrandIdentityType={setBrandIdentityType}
      PageId={2} PageNumber={2}></BrandIdentityType>
  } else if (PageId == 7) {
    return <BusinessName
      businessNameData={businessNameData}
      setBusinessNameData={setBusinessNameData}
      PageId={2} PageNumber={2}></BusinessName>
  } else if (PageId == 8) {
    return <TargetAudience
      TargetAudienceData={TargetAudienceData}
      setTargetAudienceData={setTargetAudienceData}
      updaloadFileList={updaloadFileList}
      setUploadFileList={setUploadFileList}
      targetAudienceFormData={targetAudienceFormData}
      setTargetAudienceFormData={setTargetAudienceFormData}
      userEmail={userEmail}
      paramName='email'
      PageId={2} PageNumber={2}></TargetAudience>
  }
 
  else {

    return <EndPage></EndPage>
  }



 


};
const MasterPage = ({readOnly,userEmail}) => {
  const classes = useStyles();
  const [currentIndex, setNext] = useState(1)
  const [colourSelection, setPaletteColour] = useState([]);
  const [logoTypes, setLogoTypes] = useState([]);
  const [fontTypes, setFontTypes] = useState([]);
  const [colourShadeSelection, setColourShade] = useState(['org1', 'org3']);
  const [shapeOrPattern, setShapeOrPattern] = useState([]);
  // const [brandIdentityType, setBrandIdentityType] = useState([]);
  const [data, setData] = useState(BrandIdentityData);
  const [businessNameData, setBusinessNameData] = useState(PageJSONBusinessName)
  const [TargetAudienceData, setTargetAudienceData] = useState(PageJSONTargetAudience)
  const [updaloadFileList, setUploadFileList] = useState([])
  const [savePageIndex, setSavePageIndex] = useState(0)
  const [isErrorInSave, setError] = useState(false)
  const [isLoading, setLoadStatus] = useState(false)
  const [ColourPaletteComment, setColourPaletteComment] = useState('')
  const [targetAudienceFormData, setTargetAudienceFormData] = useState();
  const user_email = userEmail;//localStorage.getItem("emailid");
  const currentText = ColourPaletteComment;

  const DiableApiValidation = true;




  const skipAPIValidation = (() => {
    if (DiableApiValidation) {
      setNext(savePageIndex + 1)     
      return;
    }


  })


  useEffect(() => {

    if (savePageIndex == 0) {

      Promise.all([
        setWindowHeight(),
        getLogoTypeData(user_email, setLogoTypes, setError,'email'),
        getFontTypeData(user_email, setFontTypes, setError,'email'),
        getColourPaletteMain(user_email, setPaletteColour, setError,'email'),
        getColourShadeData(user_email, setColourShade, setColourPaletteComment, setError,'email'),
        getShapePatternData(user_email, setShapeOrPattern, setError,'email'),
        getBrandIdentityData(user_email, setData, setError,'email'),
        getBusinessNameData(user_email, setBusinessNameData, setError,'email'),
        getTargetAudienceData(user_email, setTargetAudienceData, setError,'email')

      ]).catch(err=>{

        console.warn(err);
      })
   


    }


    if (savePageIndex == 1) {
      skipAPIValidation();
      saveLogoTypeData(savePageIndex, user_email, logoTypes, setNext, setError, setLoadStatus)


    } else if (savePageIndex == 2) {
      skipAPIValidation();
      setLoadStatus(true)
      saveFontTypeData(savePageIndex, user_email, fontTypes, setNext, setError, setLoadStatus)
    }
    else if (savePageIndex == 3) {
      skipAPIValidation();
      saveColourPaletteMain(savePageIndex, user_email, colourSelection, setNext, setError, setLoadStatus)
    }
    else if (savePageIndex == 4) {
      skipAPIValidation();

      saveColourPaletteShade(savePageIndex, user_email, colourShadeSelection, ColourPaletteComment, setNext, setError, setLoadStatus)
    } else if (savePageIndex == 5) {
      skipAPIValidation();

      saveShapePatternData(savePageIndex, user_email, shapeOrPattern, setNext, setError, setLoadStatus)

    }
    else if (savePageIndex == 6) {
      skipAPIValidation();
      saveBrandIdentityData(savePageIndex, user_email, data, setNext, setError, setLoadStatus)

    }
    else if (savePageIndex == 7) {
      skipAPIValidation();
      saveBusinessNameData(savePageIndex, user_email, businessNameData, setNext, setError, setLoadStatus)
    }
    else if (savePageIndex == 8) {
      skipAPIValidation();
     saveTargetAudienceData(savePageIndex, targetAudienceFormData, setNext, setError,user_email)
    }
     

  
    window.scrollTo(0, 0);

  }, [savePageIndex])

  /**
   * 
   *   <div onClick={(e)=>{setNext(currentIndex-1)}}>Back</div>
   */

  if (currentIndex < 9)

    return (
      <div>
        <BandProgressBar isLoading={isLoading}></BandProgressBar>
        <ErrorMessage isError={isErrorInSave}></ErrorMessage>
        <Grid id="gridTopLevel">
          <Grid item id="gridHeaderSection">
            <Box>
              <Container>
                
                <Header
                  numbertitle={PageContentData[currentIndex - 1].PageNumberTittle}
                  title={PageContentData[currentIndex - 1].PageTittle}
                  subtitle={PageContentData[currentIndex - 1].Subtitle}
                />
              </Container>
            </Box>
          </Grid>
          <Grid item className="page-box-container">
            <Box style={{height: "inherit" }} >
              <Container>
                <Page
                  PageId={(readOnly?99:currentIndex)}
                  setPaletteColour={setPaletteColour}
                  defaultData={colourSelection}
                  setLogoTypes={setLogoTypes}
                  logoTypes={logoTypes}
                  fontTypes={fontTypes}
                  setFontTypes={setFontTypes}
                  setColourShade={setColourShade}
                  currentColourShade={colourShadeSelection}
                  shapeOrPattern={shapeOrPattern}
                  setShapeOrPattern={setShapeOrPattern}
                  brandIdentityType={data}
                  setBrandIdentityType={setData}
                  businessNameData={businessNameData}
                  setBusinessNameData={setBusinessNameData}
                  TargetAudienceData={TargetAudienceData}
                  setTargetAudienceData={setTargetAudienceData}
                  updaloadFileList={updaloadFileList}
                  setUploadFileList={setUploadFileList}
                  ColourPaletteComment={ColourPaletteComment}
                  setColourPaletteComment={setColourPaletteComment}
                  currentText={currentText}
                  targetAudienceFormData={targetAudienceFormData}
                  setTargetAudienceFormData={setTargetAudienceFormData}
                  userEmail={user_email}
                >



                </Page>

              </Container>
            </Box>
          </Grid>
          <Grid item  id="gridFooterSection" className="footerCon" >
            <Box>
              <Container>
              <div className="MuiContainer-root MuiContainer-maxWidthLg">
                <div>
                
                  <Footer currentLocation={currentIndex}
                    PageNumber={PageContentData[currentIndex - 1].PageNumber} href={"/completed"}
                    nextButtonText={PageContentData[currentIndex - 1].PageNumber == 7 ? "Submit" : "Next"}
                    savePageIndex={savePageIndex}
                    setSavePageIndex={setSavePageIndex}
setNext={setNext}
                  ></Footer>
                  </div>
                </div>
              </Container>
              <br></br>
            </Box>
          </Grid>

        </Grid>
         
        <div id="backgroundGraphicBottom">
        <BackgroundGraphic />  
        
        </div>
      </div>




    )

  else
    return (<EndPage></EndPage>)



}





export default MasterPage
