import { Box, Container, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'


const Header = ({ numbertitle, title, subtitle }) => {


  return (
    <Container >
      <Box>
        <Grid spacing={3}>
    <header className='header'>
      <h3>{numbertitle}</h3>
      <h1>{title}</h1>
      <p>{subtitle}</p>


    </header>
    </Grid>
    </Box>
    </Container>
  )
}

Header.defaultProps = {
  numbertitle: '1. Chose your',
  title: 'Logo Type',
  subtitle: 'You can select as many as you like.',
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
}

// CSS in JS
// const headingStyle = {
//   color: 'red',
//   backgroundColor: 'black',
// }

export default Header
