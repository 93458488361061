import { useLocation } from 'react-router-dom'
import Navigator from '../Controllers/Navigator/Navigator'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '../../components/Controllers/Buttons/ButtonCompomentIdeal'
import BackgroundGraphic from '../BackgroundGraphic'
import ButtonBase from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "40px"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


const NextButtonWithCondition = ({ text, onClick, currentLocation, href }) => {

  if (text == "Next") {
    return <Button text={text} onclick={onClick} currentPage={currentLocation} />
  } else {
    return <ButtonBase text={text} href={href} ></ButtonBase>
  }


}




const Footer = ({ onAdd, PageNumber, currentLocation, nextButtonText, href, savePageIndex,
   setSavePageIndex,
   setNext }) => {
  const classes = useStyles();
  const location = useLocation()
  return (
    <footer >
      <Grid container xl={12} md={12}>
        <Grid item xl={3} md={3} sm={12} xs={12} ></Grid>
        <Grid item xl={6} md={6} sm={12} xs={12} style={{  display: "flex", justifyContent: "center" ,paddingTop:"10px"}}>
           <Navigator currentLocation={currentLocation} PageNumber={PageNumber} setSavePageIndex={setSavePageIndex} setNext={setNext}/>
        </Grid>
        <Grid item xl={3} md={3} sm={12} xs={12} style={{  justifyContent: "right",paddingTop:"10px" }}>    <Button text={nextButtonText}
          savePageIndex={savePageIndex}
          setSavePageIndex={setSavePageIndex}


          currentPage={currentLocation} href={href} /></Grid>

      </Grid>

 
    </footer>
  )
}
//    <Button text={nextButtonText} onClick={onClick}  currentPage={currentLocation} />

/*
      <div className='btnContainer'>
        {location.pathname === '/' && (
          <Button
            text={showAdd ? 'Close' : 'Add'}
            onClick={onAdd}
          />
        )}
      </div>
*/
export default Footer

function setWindowHeight(){
      var windowHeight = window.innerHeight;
      var windowWidth = window.innerWidth;
            
      var footerCon = document.getElementById("gridFooterSection");
      var mainPageCon = document.getElementById("start-page-main-container");
      var mainPageInnerCon = document.getElementById("container-start-page");
      var qutPageCon = document.getElementById("gridTopLevel");
   

        
            if (windowHeight < 920) {
                if (windowWidth > 1200) {
                    if(footerCon){
                      footerCon.classList.toggle('makeStatic', true);
                    }
                    if(mainPageCon){ 
                        mainPageCon.classList.toggle('makeHeightAuto', true);
                        mainPageInnerCon.classList.toggle('makeTransformZero', true);
                    }
                    if(qutPageCon){ 
                        qutPageCon.classList.toggle('makeHeightAuto', true);
                    }

                }else {
                  if(footerCon){
                    footerCon.classList.toggle('makeStatic', false);
                  }
                   if(mainPageCon){ 
                        mainPageCon.classList.toggle('makeHeightAuto', false);
                        mainPageInnerCon.classList.toggle('makeTransformZero', false);
                    }
                                        if(qutPageCon){ 
                        qutPageCon.classList.toggle('makeHeightAuto', true);
                    }
                }
        } else {
          if(footerCon){
            footerCon.classList.toggle('makeStatic', false);
            
          }
           if(mainPageCon){ 
                        mainPageCon.classList.toggle('makeHeightAuto', false);
                        mainPageInnerCon.classList.toggle('makeTransformZero', false);
                    }
                                        if(qutPageCon){ 
                        qutPageCon.classList.toggle('makeHeightAuto', true);
                    }
      
      
    }
        window.addEventListener("resize",setWindowHeight,false);
}

setWindowHeight();

setTimeout(function () {
  setWindowHeight();
}, 1000)