module.exports = [

    {
        ColourId: 1,
        ColourName: 'Red Range',
        DescritionLine1: 'Power,strength,',
        DescritionLine2: 'energy',        
        ColorShadeListClass: [  'red','red','red','red','red','red'],
        ColorShadeListSelectionClass: [  'red1','red2','red3','red4','red5','red6','red7','red8','red9','red10','red11','red12'],
        IsSelected:false
    },
    {
        ColourId: 2,
        ColourName: 'Orange range',
        DescritionLine1: 'Courage, confidence,',
        DescritionLine2: 'success',
        ColorShadeListClass: [  'org','org','org','org','org','org'],
        ColorShadeListSelectionClass: [  'org1','org2','org3','org4','org5','org6','org7','org8','org9','org10','org11','org12'],
        IsSelected:false
    }
    ,
    {
        ColourId: 3,
        ColourName: 'Yellow range',
        DescritionLine1: 'Energetic, joy, ',
        DescritionLine2: 'warm',
        ColorShadeListClass: [  'yel','yel','yel','yel','yel','yel'],
        ColorShadeListSelectionClass: [  'yel1','yel2','yel3','yel4','yel5','yel6','yel7','yel8','yel9','yel10','yel11','yel12'],
        IsSelected:false
    }
    ,
    {
        ColourId: 4,
        ColourName: 'Green Range',
        DescritionLine1: 'New, fresh,',
        DescritionLine2: 'environment',
        ColorShadeListClass: [  'green','green','green','green','green','green'],
        ColorShadeListSelectionClass: [  'green1','green2','green3','green4','green5','green6','green7','green8','green9','green10','green11','green12'],
        IsSelected:false
        
    }
    ,
    {
        ColourId: 5,
        ColourName: 'Blue range',
        DescritionLine1: 'Loyalty, trust, ',
        DescritionLine2: 'intelligence',
        ColorShadeListClass: [  'blue','blue','blue','blue','blue','blue'],
        ColorShadeListSelectionClass: [  'blue1','blue2','blue3','blue4','blue5','blue6','blue7','blue8','blue9','blue10','blue11','blue12'],
        IsSelected:false
    }
    ,
    {
        ColourId: 6,
        ColourName: 'White/Grey range',
        DescritionLine1: 'Purity, clean,',
        DescritionLine2: 'neutral',
        ColorShadeListClass: [  'wgrey','wgrey','wgrey','wgrey','wgrey','wgrey'],
        ColorShadeListSelectionClass: [  'wgrey1','wgrey2','wgrey3','wgrey4','wgrey5','wgrey6','wgrey7','wgrey8','wgrey9','wgrey10','wgrey11','wgrey12'],
        IsSelected:false
    },
    {
        ColourId: 7,
        ColourName: 'Teal range',
        DescritionLine1: 'Sophisticated,',
        DescritionLine2: 'Security',        
        ColorShadeListClass: [  'teal','teal','teal','teal','teal','teal'],
        ColorShadeListSelectionClass: [  'teal1','teal2','teal3','teal4','teal5','teal6','teal7','teal8','teal9','teal10','teal11','teal12'],
        IsSelected:false
    },
    {
        ColourId: 8,
        ColourName: 'Pink range',
        DescritionLine1: 'Compassion, playful,',
        DescritionLine2: 'sweet',
        ColorShadeListClass: [  'pink','pink','pink','pink','pink','pink'],
        ColorShadeListSelectionClass: [  'pink1','pink2','pink3','pink4','pink5','pink6','pink7','pink8','pink9','pink10','pink11','pink12'],
        IsSelected:false
    }
    ,
    {
        ColourId: 9,
        ColourName: 'Purple range',
        DescritionLine1: 'Royalty, luxury,',
        DescritionLine2: 'nobility',
        ColorShadeListClass: [  'purple','purple','purple','purple','purple','purple'],
        ColorShadeListSelectionClass: [  'purple1','purple2','purple3','purple4','purple5','purple6','purple7','purple8','purple9','purple10','purple11','purple12'],
        IsSelected:false
    }
    ,
    {
        ColourId: 10,
        ColourName: 'Brown range',
        DescritionLine1: 'Outdoors, earth,',
        DescritionLine2: 'organic',
        ColorShadeListClass: [  'brown','brown','brown','brown','brown','brown'],
        ColorShadeListSelectionClass: [  'brown1','brown2','brown3','brown4','brown5','brown6','brown7','brown8','brown9','brown10','brown11','brown12'],
        IsSelected:false
        
    }
    ,
    {
        ColourId: 11,
        ColourName: 'Gold range',
        DescritionLine1: 'Wealth, prosperity,',
        DescritionLine2: 'wisdom',
        ColorShadeListClass: [  'gold','gold','gold','gold','gold','gold'],
        ColorShadeListSelectionClass: [  'gold1','gold2','gold3','gold4','gold5','gold6','gold7','gold8','gold9','gold10','gold11','gold12'],
        IsSelected:false
    }
    ,
    {
        ColourId: 12,
        ColourName: 'Black range',
        DescritionLine1: 'Formality, classy,',
        DescritionLine2: 'elegant',
        ColorShadeListClass: [  'black','black','black','black','black','black'],
        ColorShadeListSelectionClass: [  'black1','black2','black3','black4','black5','black6','black7','black8','black9','black10','black11','black12'],
        IsSelected:false
    }
  
];