import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import './navigator.css'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 12,
    },
    paper: {

        textAlign: 'center'
    },
}));


const CompletedCycle = ({ number }) => (
    <div>
        <div className="css_dash_count css_completed"  >
            &#10003;
        </div>
    </div>
)

const CurrentCycle = ({ number }) => (

    <div>
        <div className="css_dash_count css_current"    >
            {number}
        </div>
    </div>
)

const CurrentNumbrer = ({ number }) => (
    <div>
        <div className="css_dash_count "    >
            {number}
        </div>
    </div>
)

const LinkedBar = ({className}) => (
    <hr className={className}></hr>
)

const LinkedBarInvisible = () => (
    <div className="css_invisible_hr"></div>
)

const moveToPage=({setSavePageIndex,pageIndex})=>{

    setSavePageIndex(pageIndex)
}



const NavigatorCycle = ({ number, isCompleted, isCurrent,CurrentPageNumber,setNext,setSavePageIndex }) => {
    const classes = useStyles();
   const pending =eval(CurrentPageNumber)<eval(number)

   const currentpageIndicator=(number>3?number+1:number);

    return (
        <div>

            <div className="box">
              
                {eval(number) == 1 ? <LinkedBarInvisible/> : <LinkedBar className={pending?'pending':''} />}
                <div className="navi_container">
                    <div onClick={()=>{setNext(currentpageIndicator);setSavePageIndex(currentpageIndicator-1)}}>


                        {isCompleted ? <CompletedCycle /> : (isCurrent ? <CurrentCycle number={number} /> : <CurrentNumbrer number={number} />)}
                    </div>

                </div>
            </div>

        </div>
    )
}

NavigatorCycle.propTypes = {

}
//      {eval(number) == 1 ? null : <LinkedBar />}
//{isCompleted ? <CompletedCycle /> : (isCurrent ? <CurrentCycle number={number} /> : <CurrentNumbrer number={number} />)}
export default NavigatorCycle
