import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Checkbox from '../../components/Controllers/CheckboxComponent'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../../components/Header'
import images from '../../assets/varibale-list/images'
import PageData from '../../MetaData/PageContentData'
import BranTypeData from '../../MetaData/BrandIdentityData'
import './target-audience.css'
import ContinuousSlider from '../../components/Controllers/Slider/Slider'
import CommentTextArea from '../../components/Controllers/CommentTextArea/CommentTextArea'
import DropDownList from '../../components/Controllers/Dropdownlist/Dropdownlist'
import { Container, TextField } from '@material-ui/core'
import FileUploadControl from '../../components/Controllers/FileUploadControl/FileUploadControl'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(5),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));




const ImageUrlControl = ({ TargetAudienceData, setTargetAudienceData, ImageUrlText }) => {

    const [text, setText] = useState(ImageUrlText);

    useEffect(() => {

        let objCurrent = { ...TargetAudienceData, imageURL: text }
        setTargetAudienceData(objCurrent)

    }, [text])




    return (

        <div>
            <Grid>
                <div className="description-label">
                    Upload URL Link / Images
                    <span> (optional)</span>
                    <br></br>
                    <div style={{ color: "gray", fontSize: "0.8em" }}>Have you got any inspiration or pictures, photos or logos you
                        like the look of?</div>
                    <TextField id="input-with-icon-grid" value={text ? text : ImageUrlText} onChange={(e) => { setText(e.target.value) }} style={{ width: "100%" }} placeholder="https://" />
                </div>
            </Grid>
        </div>


    )

}





const TargetAudience = ({ PageId, PageNumber, TargetAudienceData, setTargetAudienceData, updaloadFileList, setUploadFileList,
    targetAudienceFormData, setTargetAudienceFormData, userEmail, paramName, isDisableAction }) => {

    const classes = useStyles();

    const placeholder_line1 = " Enter any comments or ideas here ";
    const placeholder_line2 = "  Anything iconic in your area you would like to include in"
    const placeholder_line3 = "   your logo? Do you have a tagline that you want to include?"
    const placeholder_about_you = " Describe yourself, your values & who are your main clients"

    const disableActionClass = (isDisableAction ? 'disable-action' : '')

    return (
        <div className='container'>
            <div className='inGrid'>

                <div >
                    <div className={classes.root}>
                        <Grid container >

                            <Grid item xs={12} md={6} id="colHalf">
                                <Grid item xs={12} md={12}  >

                                    <Grid item md={12} sm={12} xs={12} spacing={0} style={{ paddingBottom: "20px" }}>
                                        <div className="description-label"> 1. Target audience  </div>
                                    </Grid>
                                    <div className={disableActionClass}><DropDownList

                                        TargetAudienceData={targetAudienceFormData}
                                        setTargetAudienceData={setTargetAudienceFormData}
                                        targetAudience={TargetAudienceData.targetAudience}

                                    />
                                    </div>


                                </Grid>
                                <Grid item xs={12} md={12} id="topgap">
                                    <div className={disableActionClass}> <  CommentTextArea

                                        TargetAudienceData={targetAudienceFormData}
                                        setTargetAudienceData={setTargetAudienceFormData}
                                        label="2. Comments / ideas"
                                        UpdateField="comment"
                                        sublabel=""
                                        height="200px"
                                        currentText={TargetAudienceData.comment}
                                        placeholder={`${placeholder_line1}${"\n"} ${placeholder_line2}${"\n"}${placeholder_line3} `}
                                    /></div>



                                </Grid>




                            </Grid>
                            <Grid item xs={12} md={6} id="colHalf">


                                <Grid item xs={12} md={12} >


                                    <div className={disableActionClass}>
                                        <  CommentTextArea
                                            TargetAudienceData={targetAudienceFormData}
                                            setTargetAudienceData={setTargetAudienceFormData}
                                            label="3. About you"
                                            sublabel=""
                                            height="150px"
                                            UpdateField="aboutYou"
                                            currentText={TargetAudienceData.aboutYou}
                                            placeholder={`${placeholder_about_you}`}
                                        />

                                    </div>

                                </Grid>
                                <Grid item xs={12} md={12} >
                                    <Grid item xs={12} md={12} >
                                        <div className={disableActionClass}>
                                            <ImageUrlControl
                                                TargetAudienceData={targetAudienceFormData}
                                                setTargetAudienceData={setTargetAudienceFormData}
                                                ImageUrlText={TargetAudienceData.imageURL}


                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12}>


                                        <FileUploadControl

                                            TargetAudienceData={TargetAudienceData}
                                            setTargetAudienceData={setTargetAudienceData}
                                            updaloadFileList={updaloadFileList}
                                            setUploadFileList={setUploadFileList}
                                            targetAudienceFormData={targetAudienceFormData}
                                            setTargetAudienceFormData={setTargetAudienceFormData}
                                            userEmail={userEmail}
                                            paramName={paramName}
                                            defaultFileList={TargetAudienceData.files}
                                            disableActionClass={disableActionClass}
                                        />

                                    </Grid>


                                </Grid>



                            </Grid>

                        </Grid>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default TargetAudience
