module.exports = [

    {
        FontId: 1,
        FontName: "Serif",
        FontStyleClassName: "serif",
        SubtitleLeft: "Formal",
        SubtitleStyleLeftClassName: "/img/fontTypeIcons/Ownly_Font_Formal-01.svg",
        SubtitleRight: "Established",        
        SubtitleStyleRightClassName: "/img/fontTypeIcons/Ownly_Font_Established-01.svg",
        OverViewCaption: "Overview",
        OverViewDescription: ": Serif fonts are the most traditional ",
        line2:"font option. A serif is classified as the small ",
        line3:"extra stroke found at the end of the main ",
        line4:"vertical and horizontal strokes of some letters."
    },
    {
        FontId: 2,
        FontName: "San Serif",
        FontStyleClassName: "san-serif",
        SubtitleLeft: "Modern-left",
        SubtitleStyleLeftClassName: "/img/fontTypeIcons/Ownly_Font_Modern-01.svg",
        SubtitleRight: "Geometric",        
        SubtitleStyleRightClassName: "/img/fontTypeIcons/Ownly_Font_Geometric-01.svg",
        OverViewCaption: "Overview",
        OverViewDescription: ": Sans serif fonts have a consistent", 
        line2:" thickness and no feet like their serif counterpart. ",
        line3:"They are the most simplified of the fonts and feel",
        line4:" modern and clean.",
        line5:"",
    },

    {
        FontId: 3,
        FontName: "Script",
        FontStyleClassName: "script",
        SubtitleLeft: "Creativity",
        SubtitleStyleLeftClassName: "/img/fontTypeIcons/Ownly_Font_Creativity-01.svg",
        SubtitleRight: "Personal",        
        SubtitleStyleRightClassName: "/img/fontTypeIcons/Ownly_Font_Personal-01.svg",
        OverViewCaption: "Overview",
        OverViewDescription: ": Script fonts are generally much fancier ",
        line2:"than their serif counterparts and often resemble ",
        line3:" handwriting or cursive. They are classified as letters ",
        line4:"that are connected and flow together in ",
        line5:" a fluid motion. ",
    },

    {
        FontId: 4,
        FontName: "Rounded",
        FontStyleClassName: "rounded",
        SubtitleLeft: "Comfort",
        SubtitleStyleLeftClassName: "/img/fontTypeIcons/Ownly_Font_Comfort-01.svg",
        SubtitleRight: "Inviting",        
        SubtitleStyleRightClassName: "/img/fontTypeIcons/Ownly_Font_Inviting-01.svg",
        OverViewCaption: "Overview",
        OverViewDescription: ": Rounded typefaces have rounded corners ",
        line2:"and/or terminals. Their softness tends to ",
        line3:"make them feel friendly and inviting.",
        line4:"",
        line5:"",
    },

    {
        FontId: 5,
        FontName: "Modern",
        FontStyleClassName: "modern",
        SubtitleLeft: "Clarity",
        SubtitleStyleLeftClassName: "/img/fontTypeIcons/Ownly_Font_Clarity-01.svg",
        SubtitleRight: "Universal",        
        SubtitleStyleRightClassName: "/img/fontTypeIcons/Ownly_Font_Universal-01.svg",
        OverViewCaption: "Overview",
        OverViewDescription: ": The Modern fonts are recognisable", 
        line2:" by their thin, long horizontal serifs, and clear-cut ",
        line3:"thick/thin transitions in the strokes.",
        line4:"",
        line5:"",
    },

    {
        FontId: 6,
        FontName: "Slab Serif",
        FontStyleClassName: "slab-serif",
        SubtitleLeft: "Confidence",
        SubtitleStyleLeftClassName: "/img/fontTypeIcons/Ownly_Font_Confidence-01.svg",
        SubtitleRight: "Inviting",        
        SubtitleStyleRightClassName: "/img/fontTypeIcons/Ownly_Font_Boldness-01.svg",
        OverViewCaption: "Overview",
        OverViewDescription: ": The slabs can be either blunt and angular ",
        line2:" or rounded. Slab serifs were invented in and most ",
        line3:" popular during the nineteenth century",
        line4:"",
        line5:"",
    }
    


  
];