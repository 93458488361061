import { useState, useEffect } from 'react'
import Checkbox from '../../components/Controllers/CheckboxComponent'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import BranTypeData from '../../MetaData/BrandIdentityData'
import './business-name.css'
import ContinuousSlider from '../../components/Controllers/Slider/SliderSingley'
import SelectButtonControl from '../../components/Controllers/SelectButton/SelectButtonUnbindControl'
import StringBuilder from '../../components/Controllers/StringBuilder/StringBuilder'
import StringBuilderExample from '../../components/Controllers/StringBuilder/StringBuilderExample'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const CardFontControllerGenrator = () => {
    if (BranTypeData.length > 0) {
        return BranTypeData.map(function (each) {
            return (
                <Grid item md={12} id="sliderconbox">
                    <ContinuousSlider id="sliderconbox" sliderId={each.Id} leftString={each.LeftString} rightString={each.RightString} />
                </Grid>)
        })
    } else {
        return []
    }
}

function CheckBoxStartPage({ text }) {
    return (

        <div>
            <Checkbox text={text}></Checkbox>

        </div>
    )

}





const BusinessName = ({ PageId, PageNumber, businessNameData, setBusinessNameData }) => {

    const classes = useStyles();

    return (

        <div className='container'>
            <div className='inGrid'>
                <div >
                    <div className={classes.root}>
                        <Grid item md={12}></Grid>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                                <div className="description-label">1. Do you want your name to be part of the business name?</div>
                            </Grid>
                            <Grid item md={6} xs={12} spacing={2}>
                                <SelectButtonControl
                                    businessNameData={businessNameData}
                                    setBusinessNameData={setBusinessNameData}
                                    isDefaultChecked={businessNameData.IsNamePartOfBusinessName}
                                    checked={businessNameData.IsNamePartOfBusinessName}></SelectButtonControl>
                            </Grid>

                            <Grid item md={12} >
                                <Grid item md={12}><div className="description-label">2. What would you prefer to be the hero?</div></Grid>
                                <br></br>
                                <Grid item md={8}>
                                    <ContinuousSlider id="sliderconbox"
                                        businessNameData={businessNameData}
                                        setBusinessNameData={setBusinessNameData}
                                        BrandAgentSliderValue={businessNameData.BrandAgentSliderValue}
                                        leftString="Brand Focus" rightString="Agent Focus" />
                                </Grid>
                                <br></br>
                                <Grid item md={12}><div className="description-label">3. Business name ideas</div></Grid>
                                <Grid item md={12}>
                                    <Grid container >
                                        <Grid item md={7} style={{ paddingTop: "10px" }}> <StringBuilder
                                            businessNameData={businessNameData}
                                            setBusinessNameData={setBusinessNameData}
                                            defaultText={businessNameData.BusinessNameIdeas}
                                        ></StringBuilder></Grid>
                                        <Grid item md={5} id="exampleContainer">
                                            <StringBuilderExample />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={2}></Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BusinessName
