import React, { useEffect } from 'react'
import axios from 'axios';
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
import './file-upload.css'
import { submitForm, deleteImageFile } from '../../../ApiService/TargetAudienceDataService'
import { API_GET_TARGET_AUDIENCE_IMAGE } from '../../../ApiConfig/ApiList'


const FileDownload = require('js-file-download');

const userStyles = makeStyles({
    root: {

        backgroundColor: '#E5E7E9',
        color: "black",
        fontWeight: "bold",
        padding: '2px 20px 2px 20px',
        borderRadius: 30,
        fontSize: '18px',
        fontFamily: "'Nunito', sans-serif;",
        textTransform: "none",
        width: '200px',
        '&:hover': {
            backgroundColor: '#19D3C5',
            color: '#FFF'
        }

    }
});


const FileListGen = ({ fileList, setTextArea, setUploadFileList, updaloadFileList, userEmail, TargetAudienceData,paramName,defaultFileList,disableActionClass }) => {
    const propertyNames =(fileList && fileList.length>0 ?fileList:defaultFileList) ;// Object.values(fileList);

   const downloadFileFromServer = (fileurl, fileName) => {

        let fileNameOrginal = fileName;

      axios({
            url: fileurl,
            method: 'GET',
            responseType: 'blob', // Important, 
        }).then((response) => {         
            FileDownload(response.data, fileNameOrginal);
        });
    }


     var api_gen=require('../../../ApiConfig/ApiGen')(paramName,userEmail);

    const TestLink = `${API_GET_TARGET_AUDIENCE_IMAGE}${api_gen}`
  




    const deleteImageFileClient = (each, index, IsFromServer,paramName) => {

        deleteImageFile(userEmail, each.name,each.id,
            setTextArea,
            setUploadFileList,
            fileList, updaloadFileList, index,
            IsFromServer,
            paramName
        )
    }



    if (propertyNames.length > 0) {
        return propertyNames.map(function (each, index) {
            return (
                <div key={index}>
                    <br></br>
                  
                    <a onClick={() => downloadFileFromServer(`${TestLink}&filename=${each.filename}`, each.filename)}>
                        <span  className= {(each.link == 'no link') ?'un-uploaded-file-css': 'uploaded-file-css'}>
                            {(each.link == 'no link') ? each.name : each.originalName}
                        </span> </a>
                    <span className={`css_file_remove_icon ${disableActionClass}`} onClick={(e) => {
                        deleteImageFileClient(each, index, (each.link == 'no link'),paramName);
                    }} >X</span>
                </div>)

        })
    } else {
        return []
    }



}



export const ButtonCompoment = ({ TargetAudienceData, setTargetAudienceData, updaloadFileList, setUploadFileList,
    targetAudienceFormData, setTargetAudienceFormData, userEmail,paramName ,defaultFileList,disableActionClass}) => {
    let fileArrayLoad = [];
    let fileArrayLoadDefault = [];

    if( TargetAudienceData.files && TargetAudienceData.files.length){
        TargetAudienceData.files.map((k) => {
      

            fileArrayLoad.push({ filename: k.filename, link: 'uploaded',id:k.id ,originalName:k.originalName})
    
        })

    }else{

        defaultFileList.map((k) => {
      

            fileArrayLoadDefault.push({ filename: k.filename, link: 'uploaded',id:k.id ,originalName:k.originalName})
    
        })
    }



    const classes = userStyles();
    const [fileList, setTextArea] = React.useState(fileArrayLoad?fileArrayLoad:fileArrayLoadDefault);


    return (
        <div>
          
            <div> 
                <br></br>
                <Grid container spacing={3}>
                    <Grid item xs={6} md={6} >
                        <Button
                           disabled={disableActionClass.length>0}
                            className={classes.root}
                            variant="contained"
                            component="label"
                        >                    Choose files
                            <input
                                type="file"
                                accept=".jpg,.png,.pdf"
                                hidden
                                onChange={(e) => {                         
                                    submitForm(userEmail, TargetAudienceData, e.target.files, setTextArea)
                                }}
                                multiple="multiple"
                            />                         
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6} sm={6}>
                        <div className="css_file_type_description">
                            (jpg, png, pdf)
                        </div>
                    </Grid>
                </Grid>


                <div>

                </div>
                <div className="css_file_container">
                    <FileListGen fileList={fileList}
                        setTextArea={setTextArea}
                        setUploadFileList={setUploadFileList}
                        updaloadFileList={defaultFileList?defaultFileList:updaloadFileList}
                        userEmail={userEmail}
                        paramName={paramName}
                        defaultFileList={fileArrayLoad}
                        TargetAudienceData={TargetAudienceData}
                        disableActionClass={disableActionClass}
                    />
                </div>

            </div>
        </div>
    )
}







export default ButtonCompoment