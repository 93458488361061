module.exports = 

    {
        IsNamePartOfBusinessName: false,
        BrandAgentSliderValue: 50,
        BusinessNameIdeas:""
       

    }
  
;