import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Checkbox from '../../components/Controllers/CheckboxComponent'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../../components/Header'
import CardShapePatternController from '../../components/Cards/ShapePattern/CardShapePatternController'
import images from '../../assets/varibale-list/images'
import Footer from '../../components/Footer';
import PageData from '../../MetaData/PageContentData'
import ShapePatternData from '../../MetaData/ShapePatterns'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const CardShapePatternControllerGenrator= ({shapeOrPattern,setShapeOrPattern}) =>{
    if (ShapePatternData.length > 0) {
        return        ShapePatternData.map(function (each) {
            return (
                <Grid item md={4} id="fontCard">
                    <CardShapePatternController 
                        cardDataSource={each}  
                        shapeOrPattern={shapeOrPattern}
                        setShapeOrPattern={setShapeOrPattern}   
                        elementId={each.ElementId}                
                    />
                </Grid>)
        })
    } else {
        return []
    }
}

function CheckBoxStartPage({ text }) {
    return (

        <div>
            <Checkbox text={text}></Checkbox>

        </div>
    )

}





const ShapePattern = ({PageId,PageNumber,shapeOrPattern,setShapeOrPattern}) => {

    const classes = useStyles();




    return (

        <div className='container'>
            <div className='inGrid'>              
                            <div >
                                <div className={classes.root}>
                                    <Grid container spacing={3}>
                                   
                                   
                                    <CardShapePatternControllerGenrator
                                     setShapeOrPattern={setShapeOrPattern}
                                     shapeOrPattern={shapeOrPattern}
                                    
                                    ></CardShapePatternControllerGenrator>
                                       
                                       
                                    </Grid>

                                </div>
                            </div>
                      </div>
                 </div>                     
              
           
    )
}

export default ShapePattern
