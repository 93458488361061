import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
//import Button from '../../Button'

const userStyles = makeStyles({
    root: {

        backgroundColor: '#19D3C5',
        color: "#075E6D",
        fontWeight: "bold",
        padding: '5px 20px 5px 20px',
        borderRadius: 30,
        fontSize: '18px',
        fontFamily :"'Nunito', 'sans-serif'",
        fontWeight:"800",
        textTransform: "none",
        width: '200px',
        '&:hover': {
            backgroundColor: '#075E6D',
            color: '#FFF'
        }

    }
})






export const ButtonCompoment = ({ text,       savePageIndex,setSavePageIndex}) => {

    const classes = userStyles();

    return (
        <div>
          
            <div > 
              <Button onClick={(k) => { setSavePageIndex(savePageIndex+1) }} className={classes.root}>{text}</Button>
              </div>

            <div  style={{display:(text=="Next")?"none":"block"}}>  
           
            
            </div>



        </div>
    )
}
/*style={{display:(text=="Next")?"block":"none"}}
 <Button
            onClick={(k) => { onClick(currentPage) }}
            className={classes.root}         
         
            href={href}
            
            >{text}
            
            </Button>



*/
export default ButtonCompoment
//  <Button onClick={(k) => { onClick(currentPage - 1) }} className={classes.root}>Pre</Button>
//<Button onClick={(k) => { onClick(currentPage + 1) }} className={classes.root}>Test JSON</Button>