import React from 'react'
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types'
import './color-palette.css'



const ColourPaletteShadeGen= ({ ColorName }) =>{


 
          return (
            <Grid item xs={12}   sm={12}  md={12}    className={ `css_colour_shade_inner_palette`} ></Grid>
              
              )

  
}






function ColourPaletteShade({ColorName}) {
    return (
        <div>
          <div className="css_colour_shade_container">
        
          <Grid container spacing={0} sm={12} xl={12}>
                                  <ColourPaletteShadeGen ColorName={ColorName}>

                                  </ColourPaletteShadeGen>
</Grid>



          </div>

        </div>
    )
}


ColourPaletteShade.defaultProps = {
  ColorName:'red1'
 
}



ColourPaletteShade.propTypes = {
  ColorName:PropTypes.string
}

export default ColourPaletteShade

