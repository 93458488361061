
import React,{useState,useEffect} from 'react'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EmailIcon from '@material-ui/icons/MailOutline'
import Grid from '@material-ui/core/Grid';
import validator from 'validator'

const userStyles = makeStyles({
    root: {
        fontFamily :"'Nunito', 'sans-serif'",
        backgroundColor: 'white',
        borderRadius: 30,
        padding: '5px 10px 5px 10px',
        border: 0,
       
    }
})

export const EmailTextBox = ({  userStartDate,setUserStartDate,setValidityOfEmail}) => {
    const classes = userStyles();
const [emailid, setEmailId]=useState();

useEffect(() => {
   
let userDataUpdated={...userStartDate,emailid:emailid}
setUserStartDate(userDataUpdated)
if (emailid!=undefined &&    emailid.length>0 && emailid.indexOf("@")>-1 && validator.isEmail(emailid)){
    setValidityOfEmail(true)

}else{
    setValidityOfEmail(false)
}




}, [emailid])



    return (
        <div>
            <div >
          
                <Grid item xl={8} md={8}  className={classes.root} container spacing={1} alignItems="flex-end">
                    <Grid item xs={12} md={12}  className="email-control-start-page">
                    <EmailIcon style={{fontSize:20}}/>                     
                   
                        <TextField   onChange={(e)=>{setEmailId(e.target.value)}} style={{width:"90%"}}  id="input-with-icon-grid" placeholder="Enter your email address" />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default EmailTextBox
