import axios from "axios";
import ApiList from "../ApiConfig/ApiList";


export async function saveBrandIdentityData(currentSaveId, email, data, setNextOnCallback, setError, setLoadStatus) {
  const dataForSent = data.map((k) => {
    return { id: k.id, left: '_', right: '_', sliderValue: k.value }
  })
  var obj = { email: email, data: dataForSent }
  axios.post(ApiList.API_SAVE_BRAND_IDENTITY,
    JSON.stringify(obj), {
    headers: {
      'Content-Type': 'application/json',
    }
  }
  )
    .then((response) => {
      if (response.data.success == true) {
        setError(false)
        setLoadStatus(false)
        setNextOnCallback(currentSaveId + 1);
      } 
    }, (error) => {
      console.log(error);
      setError(true)
    });
}


export async function getBrandIdentityData(email, callbackevent, setError, paramName) {
  let rtArrayData = [];
  var api_gen = require('../ApiConfig/ApiGen')(paramName, email);

  axios.get(ApiList.API_GET_BRAND_IDENTITY + `${api_gen}`
  )
    .then((response) => {
      if (response.data.data != null && response.data.data.length > 0) {
        rtArrayData = response.data.data.map((k) => {
          return { id: eval(k.id), value: eval(k.sliderValue) }
        })
      }
      setError(false)
      callbackevent(rtArrayData);
    }, (error) => {    
      setError(true)
    });
  return rtArrayData;
}