module.exports = [

    {
        Id: 1,
        LeftString: 'Modern',
        RightString: 'Classic',
        value:50

    },
    {
        Id: 2,
        LeftString: 'Luxury',
        RightString: 'Economical',
        value:50

    },
    {
        Id: 3,
        LeftString: 'Playful',
        RightString: 'Serious',
        value:50

    }, {
        Id: 4,
        LeftString: 'Loud',
        RightString: 'Quiet',
        value:50

    },
    {
        Id: 5,
        LeftString: 'Complex',
        RightString: 'Simple',
        value:50

    },
    {
        Id: 6,
        LeftString: 'Feminine',
        RightString: 'Masculine',
        value:50

    }
   
  
];