module.exports = [

    {
       Id : 1,
       header:"Icon / Symbol",
       description:"A shape or symbol that best represents your brand.",
       checked:true

    },
    {
        Id: 2,
        header:"Name / Word",
        description:"Your company name as an element of your logo",
        checked:true

    },

    {
        Id: 3,
        header:"Initial / Monogram",
        description:"Use a mixture of letters and symbols as an element of your logo",
        checked:false
    },

    

  
];