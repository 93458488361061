
import './progress-bar.css'
const BandProgressBar=({isLoading})=>{
    if(isLoading){
return(
    
<div>
<div className="meter">
    <span style={{width:"80%;"}}><span className="progress"></span></span>
</div>
</div>

)}
else
return null;


}

export default BandProgressBar;