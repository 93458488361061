import { useState,useEffect } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Checkbox from '../components/Controllers/CheckboxComponent'
import EmailTextBox from '../components/Controllers/EmailTextBox/EmailTextBox'
import Button from '../components/Controllers/Buttons/ButtonCompoment'
import Grid from '@material-ui/core/Grid';
import './StartPage.css'
import logo from '../assets/images/Ownly_Logo-01.svg'
import { makeStyles } from '@material-ui/core/styles';
import { saveUserData } from '../ApiService/UserDataService'
import { Container } from '@material-ui/core'
import SelectionPages from '../MasterPage/MainPage copy'
import BandProgressBar from '../components/Controllers/ProgressBar/BandProgressBar'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        fontFamily: "'Nunito', 'sans-serif'",
    },
    paper: {
        padding: theme.spacing(0),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

function CheckBoxStartPage({ text, setCondition }) {
    return (
        <div>
            <Checkbox text={text}
                setCondition={setCondition}
            ></Checkbox>
        </div>)
}

const ValidateErrorMessage=({isValid})=>
{
   


if (!isValid) {
    return  <div style={{color:"red"}}>
    Please tick the conditions and fill in your email address
       </div>;
  }
  return null;

}


const StartPage = ({ setEmail }) => {

    const classes = useStyles();
    const [userStartDate, setUserStartDate] = useState({})
    const [isConditionOne, setConditionOne] = useState(false)
    const [isConditionTwo, setConditionTwo] = useState(false)
    const [isValiedEmail, setValidityOfEmail] = useState([])
    const [isReadToStart, setGetStarted] = useState(false)//set fource
    const [loadingdata, setLoaddingStatus] = useState(false)
    const [isPageValid,setValidatePage]=useState(true)
    const [isButtonClicked,setButtonClicked]=useState(false)
    const [isLoading,setLoadingStatus]=useState(false)
    const sendData = (e, data) => {   
        setLoadingStatus(true);
        localStorage.setItem("emailid", data);
        const re = saveUserData(data, setGetStarted)
    }


useEffect(() => {
 if(isButtonClicked)
{
        const IsValid= (isConditionOne && isConditionTwo && isValiedEmail);
        setValidatePage(IsValid)
}


    
}, [isValiedEmail])


useEffect(() => {
    if(isReadToStart)
   {
    setLoadingStatus(false);
   }
   
   
       
   }, [isReadToStart])
   

if(!isReadToStart){
    return (
        <Router>
            {loadingdata && <div>Loading..................</div>}
            <BandProgressBar isLoading={isLoading}></BandProgressBar>          
            <Route
                path='/'
                exact
                render={(props) => (
                    <div className="start-page-main-container" id="start-page-main-container">
                        <div className='container-start-page' id="container-start-page">
                            <div className='inGrid'>
                                <div className={classes.root}></div>
                                <Container>
                                    <Grid container>

                                        <Grid item xs={12} sm={12} md={8} xl={8}  > <div className="top-title">LET’S START CREATING YOUR</div></Grid>
                                        <Grid item xs={12} sm={12} md={4} xl={4} >  <img className="logo_small" src={logo}  ></img></Grid>
                                        <Grid item xs={12} sm={12} md={8} xl={8}  > <div className="end-page-main-title"> OWN BRAND.
                                            <div className="tittle-underline"></div>
                                        </div></Grid>
                                        <Grid item xs={12} sm={12} md={8} xl={8}  >  <div className="bottom-title">  Let us help build your own brand identity.</div></Grid>
                                        <Grid item xs={12} sm={12} md={8} xl={8} >   <Grid item xs={12} style={{ paddingTop: "120px" }}>
                                        <Grid item xs={12} sm={12} md={8} xl={8} style={{height:"40px"}}><ValidateErrorMessage  isValid={isPageValid}/></Grid>
                                        
                                            <CheckBoxStartPage
                                                text="I agree that Ownly own’s the designs until you have signed with us"
                                                setCondition={setConditionOne}
                                            ></CheckBoxStartPage>
                                            <CheckBoxStartPage text="I am writing at least 500K GCI (Gross Commission Income)"
                                                setCondition={setConditionTwo}
                                            ></CheckBoxStartPage>
                                        </Grid></Grid>
                                        <Grid item xs={12} sm={12} md={8} xl={8} ></Grid>
                                        <Grid item xs={12} sm={12} md={8} xl={8} style={{ paddingTop: "20px" }}>  <EmailTextBox
                                            userStartDate={userStartDate}
                                            setUserStartDate={setUserStartDate}
                                            setValidityOfEmail={setValidityOfEmail}
                                        ></EmailTextBox></Grid>
                                        <Grid item xs={12} sm={12} md={4} xl={4} style={{ paddingTop: "20px" }} > <Button text="Get Started"
                                        // href={"/selections"}
                                            enable={isConditionOne && isConditionTwo && isValiedEmail}
                                            data={userStartDate.emailid}
                                            setButtonClicked={setButtonClicked}
                                            sendData={(isConditionOne && isConditionTwo && isValiedEmail)?  sendData:setValidatePage}
                                        /></Grid>

                                    </Grid>
                                    
                                </Container>
    {/* enable={isConditionOne && isConditionTwo && isValiedEmail}*/}

                    </div>
                        </div>
                        <br></br>
                        <br></br>
                       
                      

                    </div>
                )}
            />
        </Router>
    )



}else{

    return (<div>

      <SelectionPages  userEmail={userStartDate.emailid}></SelectionPages>
    </div>)
}


}

export default StartPage
