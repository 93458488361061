import { useState } from 'react'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CardController from '../../components/Cards/Colour/ColourPaletteShadeController';
import images from '../../assets/varibale-list/images'
import CommentTextArea from '../../components/Controllers/CommentTextArea/CommentTextAreaColourPalette';
import PageData from '../../MetaData/PageContentData'
import ColourData from '../../MetaData/ColourData';
import './colourpalette.css'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


const CardColorPaletteControllerGenrator= ({setShadeColor,selectedColourName,colorId,currentColourShade,colorMainIndex,setColourShade}) =>{

//var colorIndex =(colorId-1)

    if ( Number.isInteger(colorId)   &&    ColourData[colorId-1].ColorShadeListSelectionClass!=undefined &&                    ColourData[colorId-1].ColorShadeListSelectionClass.length > 0) {     
          return        ColourData[colorId-1].ColorShadeListSelectionClass.map(function (colourDetail) {        
            return (
                <Grid item md={1} style={{padding:0,margin:0}} id="individualColorCards">
           
                    <CardController 
                        ColorName={colourDetail} 
                        setShadeColor={setShadeColor}   
                        selectedColourName={selectedColourName}   
                        currentColourShade={currentColourShade} 
                        colorMainIndex={colorMainIndex}  
                        setColourShade={setColourShade}              
                    />
                </Grid>)
        })
    } else {
        return        ColourData[0].ColorShadeListSelectionClass.map(function (colourDetail) {        
            return (
                <Grid item md={1} style={{padding:0,margin:0}} id="individualColorCards">
           
                    <CardController 
                        ColorName={colourDetail} 
                        setShadeColor={setShadeColor}   
                        selectedColourName={selectedColourName}   
                        currentColourShade={currentColourShade} 
                        colorMainIndex={colorMainIndex}  
                        setColourShade={setColourShade}              
                    />
                </Grid>)
        })
    }
}






const ColourPaletteShade = ({PageId,PageNumber,defaultData,
    setColourShade,currentColourShade,TargetAudienceData,setTargetAudienceData, 
         ColourPaletteComment,setColourPaletteComment,currentText}) => {


          

    const _PageId = PageId;
    const classes = useStyles();
    const     primaryColorIndex=(defaultData.length>0? defaultData[0]:1)   
    const    secondaryColorIndex=(defaultData.length>0? defaultData[1]:1)   


const colourObjPr=ColourData.filter(k=>(k.ColourId==primaryColorIndex));
const colourObjSc=ColourData.filter(k=>(k.ColourId==secondaryColorIndex));


const colourObjPrDefault=ColourData.filter(k=>(k.ColourId==1));
const colourObjScDefault=ColourData.filter(k=>(k.ColourId==1));


let primaryColourName=colourObjPrDefault[0].ColourName;
let secondaryColourName=colourObjScDefault[0].ColourName;

try {
    //API returns invalid data for new user
    primaryColourName=(colourObjPr[0].ColourName==undefined?'':colourObjPr[0].ColourName);
    secondaryColourName=(colourObjSc[0].ColourName==undefined?'':colourObjSc[0].ColourName);
} catch (error) {
    console.warn(error);
}





const [primaryShade,setPrimaryShade]=useState(primaryColorIndex);
const [secondaryShade,setSecondaryShade]=useState(secondaryColorIndex);
    return (
      
                           <div className='container'>
                           <div className='inGrid'>    

                            <div >
                                <div className={classes.root}>
                                      <Grid container spacing={0}>
                                        <Grid item xs={12}><div className="css_comment_description">{primaryColourName}</div></Grid>
                                        <CardColorPaletteControllerGenrator colorId={primaryColorIndex} 
                                         selectedColourName={primaryShade} 
                                         currentColourShade={currentColourShade}
                                         setColourShade={setColourShade}
                                           setShadeColor={setPrimaryShade} 
                                           colorMainIndex={0}
                                           />
                                     
                                        <Grid item xs={12}><div className="css_comment_description">{secondaryColourName}</div></Grid>
                                        <CardColorPaletteControllerGenrator 
                                        colorId={secondaryColorIndex} 
                                        selectedColourName={secondaryShade}  
                                        currentColourShade={currentColourShade}
                                        setColourShade={setColourShade}
                                        setShadeColor={setSecondaryShade}
                                        colorMainIndex={1}
                                        />

                                        <Grid item xs={12}>   <CommentTextArea
                                        label="Comments / ideas about your colour"
                                        sublabel="(optional)"
                                        currentText={ColourPaletteComment}
                                       setComment={setColourPaletteComment}
                                        placeholder="  Enter any comments, ideas or a hexcode of a colour"
                                        height="200px"                                        
                                        />

                                        </Grid>  
                                    </Grid>                                   
                                    
                                </div>
                            </div>   
                        </div>
                        
                    </div>    

           
          
    )
}

export default ColourPaletteShade
