import { BrowserRouter as Router, Route } from 'react-router-dom'
import Checkbox from '../../components/Controllers/CheckboxComponent'
import Grid from '@material-ui/core/Grid';
import './end-page.css'
import logo from '../../assets/images/Ownly_Logo-01.svg'
import paperplan from '../../assets/images/Ownly_PaperPlan-01.svg'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const EndPage = () => {
    const classes = useStyles();
    return (
        <div className="end-page-main-container">
            <div className='container'>
                <div className='inGrid'>
                    <div className={classes.root}>


                        <Grid container>
                            <Grid item lg={12} md={12} sm={12} xs={12}  >    <img className="logo_small_endpage" src={logo}  ></img></Grid>
                            <Grid  container  item lg={12} md={12} sm={12} xs={12} id="topgaplarge">

                            <Grid container item lg={7} md={7} sm={12} xs={12}  >
                            <img className="logo_paper_plane_mobile" src={paperplan}  ></img>
                            <Grid item lg={12} md={12} sm={12} xs={12}  >

                            <div className="end-page-text-container">
                                        <div className="top-title">YOUR BRANDING IS</div>
                                        <div className="end-page-main-title"> ON IT’S WAY.
                                            <div className="tittle-underline"></div>
                                        </div>
                                    </div>

                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} >  <br></br>
<br></br>
<br></br>  <div className="bottom-title">We will review your answers and contact you as soon as possible.</div></Grid>

                            </Grid>
                            <Grid item lg={5} md={5} sm={12} xs={12}  >    <img className="logo_paper_plane" src={paperplan}  ></img></Grid>
                            </Grid>

                        </Grid>

                      

                    </div>
                </div>
            </div>
        </div>

    )
}

export default EndPage
