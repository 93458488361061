import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import SelectButtonControl from '../../Controllers/SelectButton/SelectButtonControl'
import Grid from '@material-ui/core/Grid';
import './CardFontController.css'
import './font-family-class.css'
import { makeStyles } from '@material-ui/core/styles';

//import '../../assets/images/LgoTypes'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        paddingTop:'15px'
    },
}));








function CardFontController({ fontData, setFontTypes, fontTypes, ComponentKey }) {

    const [checked, setChecked] = React.useState(fontTypes.indexOf(ComponentKey) > -1)
    const classes = useStyles();

    const isDefaultTrue=(fontTypes.indexOf(ComponentKey) > -1)

    useEffect(() => {
        let localArray = [];
        fontTypes.map((e) => {

            if (localArray.indexOf(e) == -1) {
                localArray.push(e)
            }


        })
        if (checked) {
            localArray.push(ComponentKey);
        } else {
            localArray = localArray.filter(k => (k != ComponentKey));
        }
        setFontTypes(localArray);
    }, [checked])





    return (
        <div className="css_font_controller_main" >

            <div id="mobileHeight" className={isDefaultTrue ? 'container-card-selected' : 'container-card-unselected'} onClick={() => { setChecked(!checked) }}  >
                <div className='inGrid'>
                    <div className={classes.root}>     <Grid container spacing={3}>
                        <br></br>
                        <Grid item xs={12} style={{ textAlign: "center", verticalAlign: "text-bottom" ,paddingTop:"15px"}}>

                            <div className={`${fontData.FontStyleClassName} card-header font-header-first-row`}  >
                            
                            <div className="css_select_toggle">  <SelectButtonControl checked={isDefaultTrue}></SelectButtonControl></div>
                            </div>
                        </Grid>
                       
                   

                        <Grid item xs={2}></Grid>
                        <Grid item xs={4} style={{ textAlign: "center", verticalAlign: "text-bottom" }}>

                            
                            <img className="font_image" src={fontData.SubtitleStyleLeftClassName}  ></img>


                            
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "center", verticalAlign: "text-bottom" }}>
                            <img className="font_image" src={fontData.SubtitleStyleRightClassName}  ></img>
                        </Grid>




                        <Grid item xs={2}></Grid>
                        <Grid item xs={12} id="mobilePadding" className="css_overview_description" style={{ textAlign: "center", verticalAlign: "text-bottom" }}>
                            <span className="css_overview_caption">{fontData.OverViewCaption} </span>      {fontData.OverViewDescription}
                            {fontData.line2}
                            {fontData.line3}
                            {fontData.line4}

                        </Grid>

                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
                    </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}


CardFontController.defaultProps = {
    fontData: {
        FontId: 1,
        FontName: "Slab Serif",
        FontStyleName: "Logo Type.",
        SubtitleLeft: "Comfort",
        SubtitleStyleLeft: "Comfort",
        SubtitleRight: "Inviting",
        SubtitleStyleRight: "Inviting",
        OverViewCaption: "Overview",
        OverViewDescription: ": Rounded typefaces have rounded corners and/or terminals. Their softness tends to make them feel friendly and inviting."
    },

}


CardFontController.propTypes = {
    fontData: PropTypes.object

}

export default CardFontController

