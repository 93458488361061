import React ,{useEffect} from 'react'
import PropTypes from 'prop-types'
import SelectButtonControl from '../../Controllers/SelectButton/SelectButtonControl'
import Grid from '@material-ui/core/Grid';
import './ColourPaletteShadeController.css'
import './colour-palette-class.css'
import './colour-palette-shade-class.css'
import { makeStyles } from '@material-ui/core/styles';
import ColourPalette from '../../Controllers/ColourPalette/ColourPaletteShade'

//import '../../assets/images/LgoTypes'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const CardColorPaletteDescritopm = ({ ColourName, DescriptionLine1, DescriptionLine2 }) => {

    return (
        <div>
            <div className="css_colour_main_text">
                {ColourName}
            </div>
            <div className="css_colour_description">
                {DescriptionLine1}
            </div>
            <div className="css_colour_description">
                {DescriptionLine2}
            </div>
        </div>
    )
}



function cardTileClick(e) {


}





function ColourPaletteShadeController({ColorName,setShadeColor,selectedColourName,currentColourShade,colorMainIndex,setColourShade}) {
    const [checked, setChecked] = React.useState(false)
    const classes = useStyles();




 useEffect(() => {


 //setChecked(ColorName==currentColourShade[colorMainIndex])

 let shade_array = [];
 currentColourShade.map(e => { shade_array.push(e) })


 if(checked){
    shade_array.splice(colorMainIndex,1,ColorName); 
    setColourShade(shade_array);

 }else{
       setColourShade(shade_array);
 }


     return () => {
       
     }
 }, [checked])


 
/*
useEffect(() => {
    console.log("useEffect");
if(selectedColourName!=ColorName){
  setChecked(false)
}else{
    let indexOfColorShade=currentColourShade.indexOf(ColorName);
    console.log(indexOfColorShade);
    setChecked(indexOfColorShade>-1)
}       return () => {
       
     }
 }, )
*/
useEffect(() => {

    if (selectedColourName!=ColorName) {
        setChecked((currentColourShade[colorMainIndex]==ColorName))
    }
})

    return (
       
        <div className={`shade-${ColorName} css_color_palette_shade_controller_main `}>

            <div className={checked ? 'container-card-selected' : 'container-card-unselected'} onClick={(e) => { setChecked(!checked); setShadeColor(ColorName); }}  >
                <div className='inGrid'>
                    <div className={classes.root}>
                        <Grid container spacing={0}>
                            <div className="css_select_toggle_cp">  <SelectButtonControl checked={checked} hideUnCheck={true}></SelectButtonControl></div>
                            <ColourPalette ColorName={ColorName}></ColourPalette>
                        
                        </Grid>
                    </div>
                </div>
            </div> 
        </div>
    )
}





ColourPaletteShadeController.propTypes = {
    ColourName: PropTypes.string

}

export default ColourPaletteShadeController

