import React from 'react'
import PropTypes from 'prop-types'
import ExampleData from '../../../MetaData/StringBuilderExampleData'




const ExampleListGenerator= () =>{


    if (ExampleData.length > 0) {
        return        ExampleData.map(function (each,index) {
            return (
            <div  className="css_string_builder_example_data">
                {each}
            </div>
                
                )
  
        })
    } else {
        return []
    }
  
  
  
  }
  









const StringBuilderExample = props => {
    return (
        <div className="css_string_builder_example">
            
<div  className="css_string_builder_example_header">

   <p> <b>Examples:</b> </p>
   
</div>
<p><ExampleListGenerator></ExampleListGenerator></p>

        </div>
    )
}

StringBuilderExample.propTypes = {

}

export default StringBuilderExample
