export function  setWindowHeight(){
    var windowHeight = window.innerHeight;
    var windowWidth = window.innerWidth;
          
    var footerCon = document.getElementById("gridFooterSection");
    var mainPageCon = document.getElementById("start-page-main-container");
    var mainPageInnerCon = document.getElementById("container-start-page");
    var qutPageCon = document.getElementById("gridTopLevel");
 

      
          if (windowHeight < 920) {
              if (windowWidth > 1200) {
                  if(footerCon){
                    footerCon.classList.toggle('makeStatic', true);
                  }
                  if(mainPageCon){ 
                      mainPageCon.classList.toggle('makeHeightAuto', true);
                      mainPageInnerCon.classList.toggle('makeTransformZero', true);
                  }
                  if(qutPageCon){ 
                      qutPageCon.classList.toggle('makeHeightAuto', true);
                  }

              }else {
                if(footerCon){
                  footerCon.classList.toggle('makeStatic', false);
                }
                 if(mainPageCon){ 
                      mainPageCon.classList.toggle('makeHeightAuto', false);
                      mainPageInnerCon.classList.toggle('makeTransformZero', false);
                  }
                                      if(qutPageCon){ 
                      qutPageCon.classList.toggle('makeHeightAuto', true);
                  }
              }
      } else {
        if(footerCon){
          footerCon.classList.toggle('makeStatic', false);
          
        }
         if(mainPageCon){ 
                      mainPageCon.classList.toggle('makeHeightAuto', false);
                      mainPageInnerCon.classList.toggle('makeTransformZero', false);
                  }
                                      if(qutPageCon){ 
                      qutPageCon.classList.toggle('makeHeightAuto', true);
                  }
    
    
  }
      window.addEventListener("resize",setWindowHeight,false);
}