import { useState} from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import MasterPage from './MasterPage/MainPage copy'
import MasterPageFeedBack from './MasterPage/MainPageFeedback'
import StartPage from './StartPage/StartPage'



require('dotenv').config();





const App = () => { 
  const[email,setEmail]=useState('')
  return (
  
  
    <Router>   

    
      <div>
           </div> 
          
          <Route path='/'   component={() => <StartPage setEmail={setEmail} email={email}/> }    />    
          <Route path='/selections' component={MasterPage} />   
          <Route path='/feedback/:id'   component={MasterPageFeedBack}   />         
                  
    </Router>
   
  )
}
/*
 <Route path='/logotype'   component={() => <LogoType PageId={1}  PageNumber={1}/> }   />
          <Route path='/fonttype'   component={() => <FontType PageId={2} PageNumber={2}/> }   />
          <Route path='/colour-palette'   component={() => <ColourPalette PageId={3} PageNumber={3}/> }    /> 
          <Route path='/colour-palette-shade'   component={() => <ColourPaletteShade PageId={4}  PageNumber={3}/> }    />   
          <Route path='/shape-pattern'   component={() => <ShapePattern PageId={5}  PageNumber={4}/> }    />   
          <Route path='/brand-identity-type'   component={() => <BrandIdentityType PageId={6}  PageNumber={5}/> }    />   
          <Route path='/business-name'   component={() => <BusinessName PageId={7}  PageNumber={6}/> }    />   
          <Route path='/target-audience'   component={() => <TargetAudience PageId={8}  PageNumber={7}/> }    /> 
*/   
export default App









