import axios from "axios";
import ApiList from "../ApiConfig/ApiList";


export async function saveColourPaletteMain(currentSaveId, email, data, setNextOnCallback, setError, setLoadStatus) {

  const dataForSent = data.map((k) => {
    return { id: k, state: true }

  })
  let IsValidColorSelection = (dataForSent.length > 0 && dataForSent[0].id != undefined);
  let mainColor = (IsValidColorSelection ? dataForSent[0].id.toString() : '');
  let alternativeColor = (IsValidColorSelection ? dataForSent[1].id.toString() : '');

  var obj = {
    email: email,
    mainColor: mainColor,
    alternativeColor: alternativeColor
  }


  axios.post(ApiList.API_SAVE_COLOUR_MAIN,
    JSON.stringify(obj), {
    headers: {
      'Content-Type': 'application/json',
    }
  }
  )
    .then((response) => {
    
      if (response.data.success == true) {
        setError(false)
        setLoadStatus(false)
        setNextOnCallback(currentSaveId + 1);
      } else {
        console.warn(response.data.message);
      }

    }, (error) => {
      console.warn(error);
    });
}


export async function getColourPaletteMain(email, callbackevent, setError, paramName) {
  var api_gen = require('../ApiConfig/ApiGen')(paramName, email);

  axios.get(ApiList.API_GET_COLOUR_MAIN + `${api_gen}`

  )
    .then((response) => {
      let rtArrayData = [];

try {
  
  if (response.data.data!=undefined) {

    if (response.data.data.mainColor != undefined) {
      rtArrayData.push(eval(response.data.data.mainColor))
    }
    if (response.data.data.alternativeColor != undefined) {
      rtArrayData.push(eval(response.data.data.alternativeColor))
    }
  }
  setError(false)
  callbackevent(rtArrayData);
} catch (error1) {
  let rtArrayData = [];
  callbackevent(rtArrayData);
  console.warn(error1);
}
     



    }, (error) => {
      console.warn(error);
      setError(true)

    });
}



export async function saveColourPaletteShade(currentSaveId, email, data, ColourPaletteComment, setNextOnCallback, setError, setLoadStatus) {

  const dataForSent = data.map((k) => {
    return { id: k, state: true }

  })

  var obj = {
    email: email,
    mainShade: dataForSent[0].id,
    alternativeShade: dataForSent[1].id
    , comment: ColourPaletteComment
  }

  axios.post(ApiList.API_SAVE_COLOUR_SHADE,
    JSON.stringify(obj), {
    headers: {
      'Content-Type': 'application/json',
    }
  }
  )
    .then((response) => {    
      if (response.data.success == true) {
        setError(false)
        setLoadStatus(false)
        setNextOnCallback(currentSaveId + 1);
      } else {
        console.warn(response.data.message);

      }
    }, (error) => {
      console.warn(error);
      setError(true)
    });
}


export  async function getColourShadeData(email, callbackevent, callbackeventScondary, setError, paramName) {
  var api_gen = require('../ApiConfig/ApiGen')(paramName, email);
  axios.get(ApiList.API_GET_COLOUR_SHADE + `${api_gen}`

  )
    .then((response) => {


      if (response.data.data != undefined) {

        let rtArrayData = [];
        rtArrayData.push(response.data.data.mainShade.toString())
        rtArrayData.push(response.data.data.alternativeShade.toString())

        setError(false)
        callbackevent(rtArrayData);
        callbackeventScondary(response.data.data.comment)
      } 
    }, (error) => {
      console.warn(error);
      setError(true)

    });
}
