import axios from "axios";
import ApiList from "../ApiConfig/ApiList";



export async function saveBusinessNameData(currentSaveId, email, data, setNextOnCallback, setError, setLoadStatus) {

  var obj = {
    email: email,
    businessNameIdeas: data.BusinessNameIdeas,
    includeBusinessName: (data.IsNamePartOfBusinessName == null ? false : data.IsNamePartOfBusinessName),
    preference: {
      left: "Brand Focus",
      right: "Agent Focus",
      sliderValue: data.BrandAgentSliderValue
    }
  }

  axios.post(ApiList.API_SAVE_BUSINESS_NAME,
    JSON.stringify(obj), {
    headers: {
      'Content-Type': 'application/json',
    }
  }
  )
    .then((response) => {
      if (response.data.success == true) {
        setError(false)
        setLoadStatus(false)
        setNextOnCallback(currentSaveId + 1);
      } else {
        console.warn(response.data.message);     
      }
    }, (error) => {
      console.warn(error);
      setError(true)
    });
}

export async function getBusinessNameData(email, callbackevent, setError, paramName) {
  let rtArrayData = {};
  var api_gen = require('../ApiConfig/ApiGen')(paramName, email);
  axios.get(ApiList.API_GET_BUSINESS_NAME + `${api_gen}`
  )
    .then((response) => {
      /*
      {"BusinessNameIdeas":" Team & Co","BrandAgentSliderValue":20,"IsNamePartOfBusinessName":true}*/
      if (response.data.data != null) {
        rtArrayData =
        {
          BusinessNameIdeas: response.data.data.businessNameIdeas,
          BrandAgentSliderValue: response.data.data.preference.sliderValue,
          IsNamePartOfBusinessName: response.data.data.includeBusinessName
        }
      }
      setError(false)
      callbackevent(rtArrayData);
    }, (error) => {
      console.warn(error);
      setError(true)

    });

  return rtArrayData;
}