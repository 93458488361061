import React,{useEffect} from 'react'
import PropTypes from 'prop-types'
import StringAddLabel from './StringAddLabel'
import StringBuilderData from '../../../MetaData/StringBuilderData'






function getSelectedString(id, string) {

    return string;

}





const ButtonControl = ({ RowNumber, onClick, currentValue }) => {


    if (StringBuilderData.length > 0) {
        return StringBuilderData.map(function (each) {
            return (

                (each.RowNumber == RowNumber ? <StringAddLabel currentValue={currentValue} text={each.ConcatString} id={each.Id} onClick={onClick} /> : null)


            )

        })
    } else {
        return []
    }



}




const StringBuilder = ({ businessNameData,   setBusinessNameData,defaultText}) => {

    const [textAreaText, setTextArea] = React.useState(businessNameData.BusinessNameIdeas==undefined?'':businessNameData.BusinessNameIdeas);


useEffect(() => {
   let objCurrent={...businessNameData,BusinessNameIdeas:textAreaText}
   setBusinessNameData(objCurrent)

}, [textAreaText])


    return (

        <div className="css_string_builder_main_container">

            <div>
                <textarea style={{ border: "none",width:"100%" }} value={defaultText} onChange={(e) => { setTextArea(e.target.value) }}></textarea></div>
            <div>  <ButtonControl RowNumber={1} onClick={setTextArea} currentValue={textAreaText} >
            </ButtonControl></div>
            <div>  <ButtonControl RowNumber={2} onClick={setTextArea} currentValue={textAreaText}  ></ButtonControl></div>

        </div>




    )
}

StringBuilder.propTypes = {

}

export default StringBuilder
