import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { red } from '@material-ui/core/colors';
import CheckedIcon from '../../../assets/images/Ownly_Selected-01.svg'
import UnCheckedIcon from '../../../assets/images/Ownly_Unselected-01.svg'



const Results = ({ width, height }) => (
    <div id="results" className="search-results">
        <img className="logo" src={CheckedIcon} ></img>
    </div>
)

const Results_Unselected = ({ width, height }) => (
    <div id="results" className="search-results">
        <img className="logo" src={UnCheckedIcon} style={{ width: width, height: height }} ></img>
    </div>
)


const SelectButtonControl = ({ checked, hideUnCheck }) => {
    //  const [checked, setChecked] = React.useState(true)
    //

    return (
        <div >
            <div style={{ display: "none" }}> <Checkbox
                checked={checked}
            >
            </Checkbox   ></div>
            <div>    {checked ? <Results width="65px" /> : (hideUnCheck ? null : <Results_Unselected width="65px" />)}</div>

        </div>
    )
}


SelectButtonControl.defaultProps = {
    hideUnCheck: false

}



export default SelectButtonControl
