

const BASE_API =(process.env.NODE_ENV === "production"?process.env.REACT_APP_BASE_API_URL_PRO:process.env.REACT_APP_BASE_API_URL_DEV) ;

module.exports =
{


    API_SAVE_EMAIL: `${BASE_API}/ownly/connect`,
    API_SAVE_LOGO_TYPE: `${BASE_API}/ownly/LogoType`,
    API_SAVE_FONT_TYPE: `${BASE_API}/ownly/FontType`,
    API_SAVE_COLOUR_MAIN: `${BASE_API}/ownly/PickColors`,
    API_SAVE_COLOUR_SHADE: `${BASE_API}/ownly/ColorPalette`,
    API_SAVE_SHAPE_PATTERN: `${BASE_API}/ownly/Shape`,
    API_SAVE_BRAND_IDENTITY: `${BASE_API}/ownly/BrandIdentityType`,
    API_SAVE_BUSINESS_NAME: `${BASE_API}/ownly/BusinessName`,
    API_SAVE_TARGET_AUDIENCE: `${BASE_API}/ownly/TargetAudience`,

    API_GET_LOGO_TYPE: `${BASE_API}/ownly/LogoType`,
    API_GET_FONT_TYPE: `${BASE_API}/ownly/FontType`,
    API_GET_COLOUR_MAIN: `${BASE_API}/ownly/PickColors`,
    API_GET_COLOUR_SHADE: `${BASE_API}/ownly/ColorPalette`,
    API_GET_SHAPE_PATTERN: `${BASE_API}/ownly/Shape`,
    API_GET_BRAND_IDENTITY: `${BASE_API}/ownly/BrandIdentityType`,
    API_GET_BUSINESS_NAME: `${BASE_API}/ownly/BusinessName`,
    API_GET_TARGET_AUDIENCE: `${BASE_API}/ownly/TargetAudience`,
    API_GET_TARGET_AUDIENCE_IMAGE: `${BASE_API}/ownly/TargetAudience/img`,

    API_DELETE_TARGET_AUDIENCE_IMAGE: `${BASE_API}/ownly/TargetAudience/delete/img`

}