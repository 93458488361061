import React from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import Button from '../Buttons/AddButtonComponent'
import './string-builder.css'



const CurrentCycle = ({ number }) => (

    <div>
        <div className="css_string_add_cycle"    >
            {number}
        </div>
    </div>
)






const StringAddLabel = ({ text, id,onClick ,currentValue}) => {
    return (
              <div className="css_string_build_button" id={id}  onClick={(e)=>{onClick(  `${currentValue}`+' '+`${text}`); }}>

                <Grid container spacing={0}>
                    <Grid item xs={8} className="pz_css_label_text">     {text}</Grid>
                    <Grid item xs={4}>  <CurrentCycle number="+" /></Grid>
                </Grid>
        </div>
    )
}

StringAddLabel.propTypes = {
   

}

export default StringAddLabel
