
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
import './comment-text-area.css'

const userStyles = makeStyles({
    root: {

        backgroundColor: 'white',
        borderRadius: 20,
        border: 0,
        width: '100%',
    }
})


export const CommentTextArea = ({ label, sublabel, placeholder, height, currentText,setComment}) => {
    const classes = userStyles();
    const [checked, setChecked] = React.useState(false)
    const background_icon = (currentText.length==0 ? "textarea-backgroung-ico" : null)



    useEffect(() => {
     
        setChecked(false)
    }, [currentText])


    return (
      
                <Grid className={classes.root} container spacing={0} alignItems="flex-end">
                    <Grid item md={12} xl={12} lg={12}   xs={12} sm={12} spacing={0}>
                        <div className="description-label"> {label} <span>{sublabel}</span> </div>
                    </Grid>
                    <Grid item md={12} xl={12}  lg={12}   xs={12} sm={12} ></Grid>
                    <br></br>
                    <Grid item md={12} xl={12} lg={12}  spacing={0}  sm={12} xs={12} >

                        <textarea
                            className={background_icon} onChange={(e) => { setComment(e.target.value) }}
value={currentText}

                            style={{ width: "100%", height: `${height}` }}
                            placeholder={placeholder}></textarea>
                    </Grid>
                </Grid>
       
    )
}

export default CommentTextArea
