import React from 'react'
import PropTypes from 'prop-types'
import NavigatorCycle from './NavigatorCycle'
import Grid from '@material-ui/core/Grid';
import pageArray from '../../../MetaData/PageContentData'


const StaticPageArray=[

    {PageId:1},
    {PageId:2},
    {PageId:3},
    {PageId:4},
    {PageId:5},
    {PageId:6},
    {PageId:7}
]


const NavigatorGen= ({ CurrentPageId,PageNumber ,setNext,setSavePageIndex}) =>{


    if (StaticPageArray.length > 0) {
        return        StaticPageArray.map(function (each) {
            return (
                <Grid item >
                    <NavigatorCycle number={each.PageId}  
                    isCompleted={each.PageId<PageNumber} 
                    isCurrent={eval(each.PageId)==eval(PageNumber)}
                    CurrentPageNumber={CurrentPageId}
                    setNext={setNext}
                    setSavePageIndex={setSavePageIndex}
                    />
                </Grid>)

        })
    } else {
        return []
    }



}

function Navigator({ currentLocation,PageNumber ,setSavePageIndex,setNext}) {


  



    return (
        <div className="nav_control_container">
               <Grid container spacing={1} id="gridNavStyle">           
               <NavigatorGen 
                          CurrentPageId={currentLocation} 
                          PageNumber={PageNumber} setNext={setNext}
                          setSavePageIndex={setSavePageIndex}
                          />
                          
                         
               </Grid>
     
        </div>
    )
}
Navigator.propTypes = {

}

export default Navigator

